import {
	TrainingOutputMap,
	TrainingOutput,
	TrainingReducer,
} from "modules/types/training";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatTrainingOutput } from "modules/utils/training";

export const resetTrainingOutputs = (
	state: TrainingReducer,
	action: PayloadAction<{ trainingOutputs: TrainingOutput[] }>
): TrainingReducer => {
	let trainingOutputMap: TrainingOutputMap = {};

	for (let trainingOutput of action.payload.trainingOutputs)
		if (trainingOutput._id)
			trainingOutputMap[trainingOutput._id] = trainingOutput;

	return {
		...state,
		trainingOutputMap,
		init: true,
	};
};

export const setTrainingOutputs = (
	state: TrainingReducer,
	action: PayloadAction<{ trainingOutputs: TrainingOutput[] }>
): TrainingReducer => {
	let trainingOutputMap: TrainingOutputMap = { ...state.trainingOutputMap };

	const trainingOutputs = formatTrainingOutput(
		action.payload.trainingOutputs
	);

	for (let trainingOutput of trainingOutputs) {
		if (trainingOutput._id)
			trainingOutputMap[trainingOutput._id] = {
				...trainingOutputMap[trainingOutput?._id],
				...trainingOutput,
			};
	}

	return {
		...state,
		trainingOutputMap,
		init: true,
	};
};

export const deleteTrainingOutputs = (
	state: TrainingReducer,
	action: PayloadAction<{ trainingOutputIds: string[] }>
) => {
	let trainingOutputMap: TrainingOutputMap = { ...state.trainingOutputMap };

	for (let trainingOutputId of action.payload.trainingOutputIds)
		delete trainingOutputMap[trainingOutputId];

	return {
		...state,
		trainingOutputMap,
	};
};

// export const setTrainingOutputsJobDescription = (
// 	state: TrainingReducer,
// 	action: PayloadAction<{
// 		array: {
// 			trainingOutputId: string;
// 			jobDescription: TrainingJobDescription | string;
// 		}[];
// 	}>
// ): TrainingReducer => {
// 	let trainingOutputMap: TrainingOutputMap = { ...state.trainingOutputMap };

// 	for (let item of action.payload.array)
// 		if (item.trainingOutputId)
// 			trainingOutputMap[item.trainingOutputId] = {
// 				...trainingOutputMap[item.trainingOutputId],
// 				trainingJobDescription: {
// 					...trainingOutputMap[item.trainingOutputId]
// 						.trainingJobDescription,
// 					...(typeof item.jobDescription === "string"
// 						? JSON.parse(item.jobDescription)
// 						: item.jobDescription),
// 				},
// 			};

// 	return {
// 		...state,
// 		trainingOutputMap,
// 		init: true,
// 	};
// };

// export const setTrainingOutputModelResults = (
// 	state: TrainingReducer,
// 	action: PayloadAction<{
// 		array: {
// 			trainingOutputId: string;
// 			modelResults: TrainingModelResultOutput | string;
// 		}[];
// 	}>
// ): TrainingReducer => {
// 	let trainingOutputMap: TrainingOutputMap = { ...state.trainingOutputMap };

// 	for (let item of action.payload.array)
// 		if (item.trainingOutputId)
// 			trainingOutputMap[item.trainingOutputId] = {
// 				...trainingOutputMap[item.trainingOutputId],
// 				modelResults: item.modelResults
// 					? typeof item.modelResults !== "string"
// 						? formatModelResults(item.modelResults)
// 						: item.modelResults !== ""
// 						? formatModelResults(JSON.parse(item.modelResults))
// 						: ""
// 					: "",
// 			};

// 	return {
// 		...state,
// 		trainingOutputMap,
// 		init: true,
// 	};
// };
