import { RequestService } from "services/request.service";
import { FileStoragesResponse } from "modules/types/file-storage";

const requestService: RequestService = new RequestService();

export class RetrieveFileStorageApi extends RequestService {
	public retrieveFileStoragesApi = async () => {
		const result: FileStoragesResponse = await requestService.request(
			"/file-management/retrieve-many",
			"GET",
			{}
		);
		return result;
	};
}
