/*eslint no-unreachable: "off"*/
import { FileStorageReducer } from "modules/types/file-storage";
import { AnyAction } from "@reduxjs/toolkit";
import {
	FileStoragesActionType,
	FileStorageActionType,
} from "modules/types/file-storage/store";

import * as methods from "./file-storage-methods";

const initialState: FileStorageReducer = {
	init: false,
	map: {},
};

export const fileStorageReducer = (
	state = initialState,
	action: AnyAction
): FileStorageReducer => {
	switch (action.type) {
		case "RESET_FILE_STORAGES": {
			return methods.resetFileStorages(
				state,
				action as FileStoragesActionType
			);
		}
		case "SET_FILE_STORAGES": {
			return methods.setFileStorages(
				state,
				action as FileStoragesActionType
			);
		}
		case "ADD_FILE_STORAGE": {
			return methods.addFileStorage(
				state,
				action as FileStorageActionType
			);
		}
		case "UPDATE_FILE_STORAGE": {
			return methods.updateFileStorage(
				state,
				action as FileStorageActionType
			);
		}
		case "DELETE_FILE_STORAGES": {
			return methods.deleteFileStorages(
				state,
				action as FileStorageActionType
			);
		}
	}
	return state;
};
