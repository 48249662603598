import { RequestService } from "services/request.service";

const requestService: RequestService = new RequestService();

export class RetrieveTrainingApi extends RequestService {
	protected retrieveTrainingsApi = async (projectIds: string[] = []) =>
		await requestService.request("annotation-training/retrieve", "POST", {
			projectIds,
		});
}
