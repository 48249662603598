/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { Dictionary, QueryResponse } from "modules/types/dictionary";
import { OwnerType } from "modules/types/user";
import { store } from "store/store";

/**
 * DICTIONAYR ACTIONS
 */

export const resetDictionaries = (dictionaries: Dictionary[]): AnyAction => {
	return store.dispatch({
		type: "RESET_DICTIONARIES",
		payload: dictionaries,
	});
};

export const setDictionaries = (dictionaries: Dictionary[]): AnyAction => {
	return store.dispatch({
		type: "SET_DICTIONARIES",
		payload: dictionaries,
	});
};

export const addDictionary = (dictionary: Dictionary): AnyAction => {
	return store.dispatch({
		type: "ADD_DICTIONARY",
		payload: dictionary,
	});
};

export const updateDictionary = (dictionary: Dictionary): AnyAction => {
	return store.dispatch({
		type: "UPDATE_DICTIONARY",
		payload: dictionary,
	});
};

export const updateDictionaryOwnerType = (
	ressourceId: string,
	ownerType: OwnerType,
	groupId: string
) => {
	return store.dispatch({
		type: "UPDATE_DICTIONARY_OWNER",
		payload: { ressourceId, ownerType, groupId },
	});
};

export const deleteDictionary = (dictionaryId: string): AnyAction => {
	return store.dispatch({
		type: "DELETE_DICTIONARY",
		payload: { dictionaryId },
	});
};

/**
 * WORDS ACTIONS
 */

export const addDictionaryWord = (word: string): AnyAction => {
	return store.dispatch({
		type: "ADD_DICTIONARY_WORD",
		payload: word,
	});
};

export const setDictionaryWords = (words: string[]): AnyAction => {
	return store.dispatch({
		type: "SET_DICTIONARY_WORDS",
		payload: words,
	});
};

export const deleteDictionaryWord = (word: string): AnyAction => {
	return store.dispatch({
		type: "DELETE_DICTIONARY_WORD",
		payload: word,
	});
};

export const setSynonyms = (words: string[]): AnyAction => {
	return store.dispatch({
		type: "SET_SYNONYMS",
		payload: words,
	});
};

export const deleteSynonym = (word: string): AnyAction => {
	return store.dispatch({
		type: "DELETE_SYNONYM",
		payload: word,
	});
};

export const setWords = (words: string[]): AnyAction => {
	return store.dispatch({
		type: "SET_WORDS",
		payload: words,
	});
};

export const clearDictionaryWords = (): AnyAction => {
	return store.dispatch({
		type: "CLEAR_DICTIONARY_WORDS",
	});
};

export const setCount = (count: number): AnyAction => {
	return store.dispatch({
		type: "SET_COUNT",
		payload: count,
	});
};

export const setSearchQuery = (resultQuery: QueryResponse) => {
	return store.dispatch({
		type: "SET_SEARCH_QUERY",
		payload: resultQuery,
	});
};
