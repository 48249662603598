/*eslint no-unreachable: "off"*/
import { ProjectReducer } from "modules/types/project";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./project-methods";
import {
	ProjectsActionType,
	ProjectActionType,
} from "modules/types/project/store";

const initialState: ProjectReducer = {
	init: false,
	map: {},
};

export const projectReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "RESET_PROJECTS": {
			return methods.resetProjects(state, action as ProjectsActionType);
			break;
		}
		case "SET_PROJECTS": {
			return methods.setProjects(state, action as ProjectsActionType);
			break;
		}
		case "ADD_PROJECT": {
			return methods.addProject(state, action as ProjectActionType);
			break;
		}
		case "UPDATE_PROJECT": {
			return methods.updateProject(state, action as ProjectActionType);
			break;
		}
		case "UPDATE_PROJECT_OWNER": {
			return methods.updateProjectOwnerType(state, action as any);
		}
		case "DELETE_PROJECT": {
			return methods.deleteProject(state, action as any);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
