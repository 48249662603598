import { RetrieveRessourceStore } from "./retrieve-ressource/store/retrieve-ressource.store.service";
import { RetrieveCampaignStore } from "./retrieve-annotation-campaign/store/retrieve-annotation-campaign.store.service";
import { RetrieveDictionaryStore } from "./retrieve-dictionary/store/retrieve-dictionary.store";
import { RetrieveDatasetStore } from "./retrieve-dataset/store/retrieve-dataset.store";
import { RetrieveGroupStore } from "./retrieve-group/store/retrieve-group.store";
import { RetrieveMembersStore } from "./retrieve-members/store/retrieve-members.store";
import { RetrieveOntologyStore } from "./retrieve-ontology/store/retrieve-ontology.store";
import { RetrieveProjectStore } from "./retrieve-project/store/retrieve-project.store";
import { RetrieveSubscriptionStore } from "./retrieve-subscription/store/retrieve-subscription.store.service";
import { RetrievePatternStore } from "./retrieve-pattern/store/retrieve-pattern.store.service";
import { RetrieveFileStorageStore } from "./retrieve-file-storage/store/retrieve-file-storage.store";
import { RetrieveUserStore } from "./retireve-user/store/retireve-user.store.service";
import { RetrieveTrainingStore } from "./retrieve-training/store/retrieve-training.store";
import { RetrieveNotificationsStore } from "./retrieve-notification/store/retrieve-notification.store";
import { RetrieveSuggestionsStore } from "./retrieve-suggestion/store/retrieve-suggestion.store";
import {
	BodyElement,
	RessourceField,
	RessourceRealType,
	RessourceType,
} from "modules/types/global";

/**
 * Retrieve Data Class Service.
 * * Only data needed for the initialization of the plateform
 * * Other retrieves are not necessary
 */
export class RetrieveDataService {
	/**
	 * Retrieve the data needed for the vizualisation of each page
	 * @param ressources
	 */
	public retrieveRessources = async (
		ressources: RessourceField[]
	): Promise<
		{
			type: RessourceType;
			exist: boolean;
			documents?: RessourceRealType[];
			count?: number;
		}[]
	> => {
		return Promise.all(
			ressources.map(async (ressource) => {
				const body: BodyElement = {
					filter: ressource.filter,
					sort: ressource.sort,
					$nin: ressource.$nin,
					$in: ressource.$in
						? ressource.force
							? ressource.$in
							: ressource.$in.filter((id) => !ressource.map[id])
						: [],
				};

				if (
					body.$in &&
					!body.$in.length &&
					ressource.$in &&
					body.$in.length !== ressource.$in.length
				)
					return { exist: false, type: ressource.type };
				else {
					const response =
						await new RetrieveRessourceStore().retrieveRessourcesStore(
							ressource.type,
							body
						);
					if (response) {
						const { documents, count } = response;
						return {
							exist: true,
							documents,
							count,
							type: ressource.type,
						};
					}
					return { exist: false, type: ressource.type };
				}
			})
		);
	};

	public retrieveAll = async () => {
		await new RetrieveCampaignStore().retrieveCampaigns();
		await new RetrieveDatasetStore().retrieveDatasets();
		await new RetrieveDictionaryStore().retrieveDictionaries();
		await new RetrieveGroupStore().retrieveGroups();
		await new RetrieveOntologyStore().retrieveOntologies();
		await new RetrieveProjectStore().retrieveProjects();

		await new RetrieveSubscriptionStore().retrieveSubscriptions();
		await new RetrieveFileStorageStore().retrieveFileStorages();
		await new RetrieveNotificationsStore().retrieveNotifications();
		return true;
	};

	/**
	 * USER retrieve
	 */
	public retrieveUser = async () =>
		await new RetrieveUserStore().retrieveUserStore();

	/**
	 * NLP retrieve
	 */
	public retrieveMembers = async (groupId: string) =>
		await new RetrieveMembersStore().retrieveGroupMembers(groupId);

	public retrieveGroups = async () =>
		await new RetrieveGroupStore().retrieveGroups();

	public retrieveDictionaries = async () =>
		await new RetrieveDictionaryStore().retrieveDictionaries();

	public retrieveDatasets = async () =>
		await new RetrieveDatasetStore().retrieveDatasets();

	public retrieveFileStorages = async () =>
		await new RetrieveFileStorageStore().retrieveFileStorages();

	public retrieveProjects = async () =>
		await new RetrieveProjectStore().retrieveProjects();

	public retrieveTrainings = async (projectIds: string[] = []) =>
		await new RetrieveTrainingStore().retrieveTrainings(projectIds);

	/**
	 * ANNOTATION retrieve
	 */
	public retrieveCampaigns = async () =>
		await new RetrieveCampaignStore().retrieveCampaigns();

	public retrievePatterns = async () =>
		await new RetrievePatternStore().retrievePatterns();

	public retrieveOntologies = async () =>
		await new RetrieveOntologyStore().retrieveOntologies();

	public retrieveNotifications = async (ressourceIds?: string[]) =>
		await new RetrieveNotificationsStore().retrieveNotifications(
			ressourceIds
		);

	public retrieveSuggestions = async () =>
		await new RetrieveSuggestionsStore().retrieveSuggestions();

	public retrieveSubscriptions = async () =>
		await new RetrieveSubscriptionStore().retrieveSubscriptions();
}
