/**
 * Format project classificationKnowledgeId to ontology & id
 * @param project
 */
export const formatProject = (project: any) => {
	if (project.classificationKnowledgeId?._id) {
		const _ontology = { ...project.classificationKnowledgeId };

		let _project = { ...project };
		_project.classificationKnowledgeId = _ontology._id;

		_project.ontologyIds = _project.knowledgeIds || [];
		delete _project.knowledgeIds;

		return { ontology: _ontology, project: _project };
	}
	return { ontology: null, project: project };
};
