const GeneralConditionsUse = (props: any) => {
	return (
		<div className="general-conditions-use">
			<iframe
				style={{
					width: "100%",
					height: "100vh",
					position: "absolute",
					top: 0,
					left: 0,
				}}
				id="iframepdf"
				src="images/cgu.pdf"
			></iframe>
		</div>
	);
};

export default GeneralConditionsUse;
