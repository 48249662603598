import { useState } from "react";
import { Row, Col, Typography } from "antd";
import { useNavigate, useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Countdown from "antd/lib/statistic/Countdown";
import { VerifyForm } from "./component/verify-form.component";
import { Link } from "react-router-dom";
import "./styles/verify.style.less";

export const VerifyCode = () => {
	const [valueCountdown, setValueCountdown] = useState(
		Date.now() + 15 * 60 * 1000
	);
	const resetValueCountdown = () => {
		setValueCountdown(Date.now() + 15 * 60 * 1000);
	};
	const navigate = useNavigate();
	const { email } = useParams();

	return (
		<Row justify="center" className="signup-container">
			<Col span={12} className="left-column" style={{ width: "58%" }}>
				<img
					className="lettria-logo"
					alt=""
					src="/images/lettria.svg"
					onClick={() => navigate("/")}
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/unlock-text.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/purposes.svg"
					style={{ marginLeft: "50%" }}
				/>
				<img
					alt=""
					src="/images/icons/clients.svg"
					style={{
						bottom: "48px",
						position: "absolute",
					}}
				/>
			</Col>
			<Col span={12} className="right-column" style={{ padding: "80px" }}>
				<p className="no-card">
					<ArrowLeftOutlined
						style={{ color: "#6b4eb6", marginRight: "10px" }}
					/>
					<Link to="/signup" className="no-card-link">
						Back to create your account
					</Link>
				</p>
				<Typography.Title
					level={1}
					style={{
						margin: 0,
						wordBreak: "break-word",
					}}>
					Verify your account
				</Typography.Title>
				<Typography.Paragraph
					style={{
						width: " 430px",
						height: "88px",
						marginTop: "4px",
						marginBottom: "24px",
						wordBreak: "break-word",
					}}>
					We just sent a verification code to {email}
					<br />
					<br /> Check your inbox and enter the verification code
					below to verify your email address. The code will expire in{" "}
					<Countdown
						style={{
							display: "inline-block",
							width: "fit-content",
						}}
						value={valueCountdown}
					/>
				</Typography.Paragraph>
				<VerifyForm resetValueCountdown={resetValueCountdown} />
			</Col>
		</Row>
	);
};
