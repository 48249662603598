import { AirflowDagRun } from "modules/types/training/airflow";
import {
	MLFlowExperiment,
	MLFlowModelVersion,
	MLFlowRun,
} from "modules/types/training/ml-flow";
import {
	DagRunsActionType,
	ExperimentsActionType,
	ModelVersionsActionType,
	RunsActionType,
} from "modules/types/training/store";
import { store } from "store/store";

export const setExperiments = (
	experiments: MLFlowExperiment[]
): ExperimentsActionType =>
	store.dispatch({
		type: "SET_EXPERIMENTS",
		payload: { experiments: experiments },
	});

export const setRuns = (runs: MLFlowRun[]): RunsActionType =>
	store.dispatch({
		type: "SET_RUNS",
		payload: { runs: runs },
	});

export const setDagRuns = (dagRuns: AirflowDagRun[]): DagRunsActionType =>
	store.dispatch({
		type: "SET_DAG_RUNS",
		payload: { dagRuns },
	});

export const setModelVersions = (
	projectId: string,
	modelVersions: MLFlowModelVersion[]
): ModelVersionsActionType =>
	store.dispatch({
		type: "SET_MODEL_VERSIONS",
		payload: { projectId, modelVersions },
	});
