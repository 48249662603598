/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { FileStorage } from "modules/types/file-storage";
import { store } from "store/store";

/**
 * DICTIONAYR ACTIONS
 */

export const resetFileStorages = (fileStorages: FileStorage[]): AnyAction => {
	return store.dispatch({
		type: "RESET_FILE_STORAGES",
		payload: fileStorages,
	});
};

export const setFileStorages = (fileStorages: FileStorage[]): AnyAction => {
	return store.dispatch({
		type: "SET_FILE_STORAGES",
		payload: fileStorages,
	});
};

export const addFileStorage = (fileStorage: FileStorage): AnyAction => {
	return store.dispatch({
		type: "ADD_FILE_STORAGE",
		payload: fileStorage,
	});
};

export const updateFileStorage = (fileStorage: FileStorage): AnyAction => {
	return store.dispatch({
		type: "UPDATE_FILE_STORAGE",
		payload: fileStorage,
	});
};

export const deleteFileStorages = (fileStorageIds: string[]): AnyAction => {
	return store.dispatch({
		type: "DELETE_FILE_STORAGES",
		payload: fileStorageIds,
	});
};
