import {
	FileStorageMap,
	FileStorage,
	FileStorageReducer,
} from "modules/types/file-storage";
import { PayloadAction } from "@reduxjs/toolkit";

/**
 * DICTIONARY STATE
 */

export const resetFileStorages = (
	state: FileStorageReducer,
	action: PayloadAction<FileStorage[]>
): FileStorageReducer => {
	let map: FileStorageMap = {};
	for (let fileStorage of action.payload) map[fileStorage?._id] = fileStorage;
	return {
		...state,
		map,
		init: true,
	};
};

export const setFileStorages = (
	state: FileStorageReducer,
	action: PayloadAction<FileStorage[]>
): FileStorageReducer => {
	let map: FileStorageMap = { ...state.map };
	for (let fileStorage of action.payload)
		if (fileStorage._id)
			map[fileStorage._id] = { ...map[fileStorage._id], ...fileStorage };

	return {
		...state,
		map,
		init: true,
	};
};

export const addFileStorage = (
	state: FileStorageReducer,
	action: PayloadAction<FileStorage>
) => {
	let fileStoragesMap: FileStorageMap = { ...state.map };
	fileStoragesMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: fileStoragesMap,
	};
};

export const updateFileStorage = (
	state: FileStorageReducer,
	action: PayloadAction<FileStorage>
) => {
	let fileStoragesMap: FileStorageMap = { ...state.map };
	fileStoragesMap[action.payload._id] = action.payload;
	return {
		...state,
		map: fileStoragesMap,
	};
};

export const deleteFileStorages = (
	state: FileStorageReducer,
	action: PayloadAction<string[]>
) => {
	let fileStoragesMap: FileStorageMap = { ...state.map };

	for (let fileStorageId of action.payload)
		delete fileStoragesMap[fileStorageId];

	return {
		...state,
		map: fileStoragesMap,
	};
};
