/*eslint no-unreachable: "off"*/
import { AnyAction } from "@reduxjs/toolkit";
import { TrainingReducer } from "modules/types/training";
import {
	TrainingsActionType,
	TrainingIdsActionType,
	TrainingOutputsActionType,
	ExperimentsActionType,
	RunsActionType,
	DagRunsActionType,
	ModelVersionsActionType,
} from "modules/types/training/store";

import * as methods from "./methods";

const initialState: TrainingReducer = {
	init: false,
	trainingMap: {},
	trainingOutputMap: {},
	experimentMap: {},
	runMap: {},
	dagRunMap: {},
	modelVersionMap: {},
};

export const trainingReducer = (
	state = initialState,
	action: AnyAction
): TrainingReducer => {
	switch (action.type) {
		case "RESET_TRAININGS":
			return methods.resetTrainings(state, action as TrainingsActionType);

		case "SET_TRAININGS":
			return methods.setTrainings(state, action as TrainingsActionType);

		case "DELETE_TRAININGS":
			return methods.deleteTrainings(
				state,
				action as TrainingIdsActionType
			);

		case "RESET_TRAINING_OUTPUTS":
			return methods.resetTrainingOutputs(
				state,
				action as TrainingOutputsActionType
			);

		case "SET_TRAINING_OUTPUTS":
			return methods.setTrainingOutputs(
				state,
				action as TrainingOutputsActionType
			);
		case "SET_EXPERIMENTS":
			return methods.setExperiments(
				state,
				action as ExperimentsActionType
			);
		case "SET_RUNS":
			return methods.setRuns(state, action as RunsActionType);
		case "SET_DAG_RUNS":
			return methods.setDagRuns(state, action as DagRunsActionType);
		case "SET_MODEL_VERSIONS":
			return methods.setModelVersions(
				state,
				action as ModelVersionsActionType
			);
		case "DELETE_TRAINING_OUTPUTS":
			return methods.deleteTrainingOutputs(state, action as any);
	}
	return state;
};
