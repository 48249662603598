/*eslint no-unreachable: "off"*/
import { AnyAction } from "@reduxjs/toolkit";
import { GroupReducer } from "modules/types/group";
import {
	GroupsActionType,
	GroupActionType,
	MembersActionType,
} from "modules/types/group/store";
import * as methods from "./group-methods";

const initialState: GroupReducer = {
	init: false,
	map: {},
	members: {},
};

export const groupReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "RESET_GROUPS": {
			return methods.resetGroups(state, action as GroupsActionType);
			break;
		}
		case "SET_GROUPS": {
			return methods.setGroups(state, action as GroupsActionType);
			break;
		}
		case "SET_MEMBERS": {
			return methods.setGroupMembers(state, action as MembersActionType);
			break;
		}
		/**
		 * To delete ?
		 */
		case "ADD_GROUP": {
			return methods.addGroup(state, action as GroupActionType);
			break;
		}
		/**
		 * To delete ?
		 */
		case "UPDATE_GROUP": {
			return methods.updateGroup(state, action as GroupActionType);
			break;
		}
		/**
		 * To delete ?
		 */
		case "DELETE_GROUP": {
			return methods.deleteGroup(state, action as any);
			break;
		}
		/**
		 * To delete ?
		 */
		case "MANAGE_ROLE": {
			return methods.manageRole(state, action as any);
			break;
		}
		case "SELECT_GROUP": {
			return methods.selectGroup(state, action as any);
			break;
		}
		case "ADD_RESSOURCE_TO_GROUP": {
			return methods.addRessourceToGroup(state, action as any);
			break;
		}
		case "DELETE_RESSOURCE_FROM_GROUP": {
			return methods.deleteRessourceFromGroup(state, action as any);
			break;
		}
		case "DELETE_MEMBER": {
			return methods.deleteGroupMember(state, action as any);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
