/* eslint-disable no-unreachable */
import { AnyAction } from "@reduxjs/toolkit";
import { BooleanActionType } from "modules/types/global/store";
import { UserReducer, UserData, UserProfile } from "modules/types/user";
import { UserDataActionType } from "modules/types/user/store";
import {
	deleteUser,
	setLoggedIn,
	setUser,
	addRessourceToUser,
	deleteRessourceFromUser,
} from "./user-methods";

const profile: UserProfile = {
	firstName: "",
	lastName: "",
	profession: "",
	usecase: [],
};

const data: UserData = {
	username: "",
	role: "User",
	emailVerified: false,
	lastConnection: "",
	origin: "",
	_id: "",
	email: "",
	password: "",
	profile: profile,
	verifyEmailToken: "",
	dictionaryIds: [],
	patternIds: [],
	ontologyIds: [],
	groupIds: [],
	projectIds: [],
	dataSetIds: [],
	annotationCampaignIds: [],
	createdAt: "",
	updatedAt: "",
};

const initialState = {
	loggedIn: false,
	init: false,
	data: data,
} as UserReducer;

export const userReducer = (
	state = initialState,
	action: AnyAction
): UserReducer => {
	switch (action.type) {
		case "SET_USER": {
			return setUser(state, action as UserDataActionType);
			break;
		}
		case "DELETE_USER": {
			return deleteUser(state, action as UserDataActionType);
			break;
		}
		case "SET_LOGGED_IN": {
			return setLoggedIn(state, action as BooleanActionType);
			break;
		}
		case "ADD_RESSOURCE_TO_USER": {
			return addRessourceToUser(state, action as any);
			break;
		}
		case "DELETE_RESSOURCE_TO_USER": {
			return deleteRessourceFromUser(state, action as any);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
