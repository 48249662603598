import { setDatasets } from "store/dataset/dataset-actions";
import { setDictionaries } from "store/dictionary/dictionary-actions";
import { setCampaigns } from "store/annotation-campaign/annotation-campaign-actions";
import { setGroupMembers } from "store/group/group-actions";
import { GroupViewApi } from "../api/group-view.api.service";
import { setProjects } from "store/project/project-actions";
import { setOntologies } from "store/ontology/actions";
import { setPatterns } from "store/pattern/pattern-actions";
import { Pattern } from "modules/types/pattern";
import { formatProject } from "modules/utils/project";

export class GroupViewStore extends GroupViewApi {
	public retrieveAllGroupsMembersStore = async () => {
		const result = await this.retrieveAllGroupsMembersApi();
		if (result.groupMembers?.length) {
			for (let groupMembers of result.groupMembers) {
				setGroupMembers(groupMembers.groupId, groupMembers.members);
			}
		}
		return result;
	};
	public retrieveMembersStore = async (groupId: string) => {
		const result = await this.retrieveMembersApi(groupId);
		if (result?.members) {
			setGroupMembers(groupId, result.members);
		}
		return result;
	};

	/**
	 * Retrieve campaigns from ids
	 * @param campaignIds
	 * @returns
	 */
	public retrieveCampaignsStore = async (campaignIds: string[]) => {
		const result = await this.retrieveCampaignsApi(campaignIds);
		if (result.length) {
			setCampaigns(result.filter((campaign) => campaign._id));
		}
		return result;
	};

	/**
	 * Retrieve dictionaries from ids
	 * @param dictionaryIds
	 * @returns
	 */
	public retrieveDictionariesStore = async (dictionaryIds: string[]) => {
		const result = await this.retrieveDictionariesApi(dictionaryIds);
		if (result.length) {
			setDictionaries(result.filter((dictionary) => dictionary._id));
		}
		return result;
	};

	/**
	 * Retrieve projects from ids
	 * @param projectIds
	 * @returns
	 */
	public retrieveProjectsStore = async (projectIds: string[]) => {
		const result = await this.retrieveProjectsApi(projectIds);
		if (result && Array.isArray(result) && result.length) {
			setProjects(
				result
					.filter((project) => project._id)
					.map((_project) => {
						const { ontology, project } = formatProject(_project);
						if (ontology) {
							setOntologies([ontology]);
						}
						return project;
					})
			);
		}
		return result;
	};

	/**
	 * Retrieve ontology from ids
	 * @param ontologyIds
	 * @returns
	 */
	public retrieveOntologiesStore = async (ontologyIds: string[]) => {
		const result = await this.retrieveOntologiesApi(ontologyIds);
		if (result.length) {
			setOntologies(result.filter((ontology) => ontology._id));
		}
		return result;
	};

	/**
	 * Retrieve patterns from ids
	 * @param patternIds
	 * @returns
	 */
	public retrievePatternsStore = async (patternIds: string[]) => {
		const result: { patterns: Pattern[] } = await this.retrieveDataByIdsApi(
			"pattern",
			patternIds
		);
		if (result.patterns) {
			setPatterns(result.patterns.filter((pattern) => pattern._id));
		}
		return result;
	};

	/**
	 * Retrieve DataSets from ids
	 * @param dataSetIds
	 * @returns
	 */
	public retrieveDataSetsStore = async (dataSetIds: string[]) => {
		const result = await this.retrieveDataSetsApi(dataSetIds);
		if (result.length) {
			setDatasets(result.filter((dataset) => dataset._id));
		}
		return result;
	};
}
