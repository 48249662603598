/*eslint no-unreachable: "off"*/
import { TokenReducer } from "modules/types/token";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./token-methods";
import { TokensActionType } from "modules/types/token/store";

const initialState: TokenReducer = {
	init: false,
	map: {},
	list: [],
	tokenCards: [],
};

export const tokenReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "RESET_PROJECT_TOKENS": {
			return methods.resetProjectTokens(
				state,
				action as TokensActionType
			);
			break;
		}
		case "SET_PROJECT_TOKENS": {
			return methods.setProjectTokens(state, action as TokensActionType);
			break;
		}
		// case "ADD_TOKEN": {
		// 	return methods.addToken(state, action as TokenActionType);
		// 	break;
		// }
		// case "UPDATE_TOKEN": {
		// 	return methods.updateToken(state, action as TokenActionType);
		// 	break;
		// }
		// case "DELETE_TOKEN": {
		// 	return methods.deleteToken(state, action as TokenActionType);
		// 	break;
		// }
		default: {
			break;
		}
	}
	return state;
};
