import { RequestService } from "services/request.service";

export class RetrieveMembersApi extends RequestService {
	public retrieveGroupMembersApi = async (groupId: string) => {
		const result = await this.request("group/retrieve-members", "POST", {
			groupId,
		});
		return result;
	};
}
