import { setDatasets } from "store/dataset/dataset-actions";
import { RetrieveDatasetApi } from "../api/retrieve-dataset.api.service";

export class RetrieveDatasetStore extends RetrieveDatasetApi {
	/**
	 * Retrieve all dataset owned by user
	 * @returns null
	 */
	public retrieveDatasets = async () => {
		const result = await this.retrieveDatasetsApi();

		setDatasets(result.dataSets);
	};
}
