/* eslint-disable no-unreachable */
import { AnyAction } from "@reduxjs/toolkit";
import { CampaignReducer } from "modules/types/annotation-campaign";
import {
	CampaignsActionType,
	CampaignActionType,
	CampaignDataActionType,
	KpiDataActionType,
	EvolutionDataActionType,
} from "modules/types/annotation-campaign/store";
import { StringActionType } from "modules/types/global/store";
import * as methods from "./annotation-campaign-methods";

const initialState: CampaignReducer = {
	init: false,
	map: {},
	dataMap: {},
	manageDataMap: {},
	dataToAnnotate: {},
	modifyingAnnotationItem: {},
};

export const annotationCampaignReducer = (
	state = initialState,
	action: AnyAction
): CampaignReducer => {
	switch (action.type) {
		case "RESET_CAMPAIGNS": {
			return methods.resetCampaigns(state, action as CampaignsActionType);
		}
		case "SET_CAMPAIGNS": {
			return methods.setCampaigns(state, action as CampaignsActionType);
		}
		case "ADD_CAMPAIGN": {
			return methods.addCampaign(state, action as CampaignActionType);
		}
		case "UPDATE_CAMPAIGN": {
			return methods.updateCampaign(state, action as CampaignActionType);
		}
		case "UPDATE_CAMPAIGN_OWNER": {
			return methods.updateCampaignOwnerType(state, action as any);
		}
		case "DELETE_CAMPAIGN": {
			return methods.deleteCampaign(state, action as StringActionType);
		}
		case "SET_CAMPAIGN_DATA": {
			return methods.setCampaignData(
				state,
				action as CampaignDataActionType
			);
		}
		case "DELETE_CAMPAIGN_DATA": {
			return methods.deleteCampaignData(
				state,
				action as StringActionType
			);
		}
		case "SET_KPI_DATA": {
			return methods.setKpiData(state, action as KpiDataActionType);
		}
		case "SET_EVOLUTION_DATA": {
			return methods.setEvolutionData(
				state,
				action as EvolutionDataActionType
			);
		}
		default: {
			break;
		}
	}
	return state;
};
