import { Notification, NotificationStatus } from "modules/types/notification";
/*eslint no-unused-vars:0*/
import { store } from "store/store";

export const resetNotification = (notifications: Notification[]) => {
	return store.dispatch({
		type: "RESET_NOTIFICATIONS",
		payload: notifications,
	});
};

export const setNotifications = (notifications: Notification[]) => {
	return store.dispatch({
		type: "SET_NOTIFICATIONS",
		payload: notifications,
	});
};

export const updateNotification = (notification: Notification) => {
	return store.dispatch({
		type: "UPDATE_NOTIFICATION",
		payload: notification,
	});
};

export const updateNotificationStatus = (
	notificationId: string,
	status: NotificationStatus
) => {
	return store.dispatch({
		type: "UPDATE_NOTIFICATION_STATUS",
		payload: {
			notificationId,
			status,
		},
	});
};

export const addNotification = (notification: Notification) => {
	return store.dispatch({
		type: "ADD_NOTIFICATION",
		payload: notification,
	});
};

export const notificationsRead = (userId: string) => {
	return store.dispatch({
		type: "NOTIFICATIONS_READ",
		payload: { userId },
	});
};

export const setAllNotificationsRead = (notificationsRead: boolean) => {
	return store.dispatch({
		type: "SET_ALL_NOTIFICATIONS_READ",
		payload: notificationsRead,
	});
};
