import { WorkspaceReducer } from "modules/types/workspace";
import { PayloadAction } from "@reduxjs/toolkit";

export const setWorkspace = (
	state: WorkspaceReducer,
	action: PayloadAction<string>
) => {
	return {
		...state,
		init: true,
		name: action.payload,
	};
};
