/* eslint-disable no-unreachable */
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./flow-methods";
import { FlowActionType, FlowReducer } from "modules/types/flow";

const initialState: FlowReducer = {
	stepIndex: 0,
	name: "",
	selectedDatasetsIds: [],
	url: "",
};

export const flowReducer = (
	state = initialState,
	action: AnyAction
): FlowReducer => {
	switch (action.type) {
		case "RESET_FLOW": {
			return methods.resetFlow(state);
		}
		case "SET_FLOW": {
			return methods.setFlow(state, action as FlowActionType);
		}
		default: {
			break;
		}
	}
	return state;
};
