import { SubscriptionResponse } from "modules/types/subscription";
import { setSubscriptions } from "store/subscription/subscription-actions";
import { RetrieveSubscriptionApi } from "../api/retrieve-subscription.api.service";

export class RetrieveSubscriptionStore extends RetrieveSubscriptionApi {
	/**
	 * Retrieve all subscription owned by user
	 * @returns null
	 */
	public retrieveSubscriptions = async () => {
		let result: SubscriptionResponse =
			await this.retrieveSubscriptionsApi();
		if (!result || !result.subscriptions) return;
		setSubscriptions(result.subscriptions);
	};
}
