import { setGroupMembers } from "store/group/group-actions";
import { RetrieveMembersApi } from "../api/retrieve-members.api.service";

export class RetrieveMembersStore extends RetrieveMembersApi {
	public retrieveGroupMembers = async (groupId: string) => {
		const result = await this.retrieveGroupMembersApi(groupId);
		if (result?.members) {
			setGroupMembers(groupId, result.members);
		}
		return result;
	};
}
