import { RequestService } from "services/request.service";
import { ProjectResponse } from "modules/types/project";

const requestService: RequestService = new RequestService();

export class RetrieveProjectApi extends RequestService {
	public retrieveProjectsApi = async () => {
		const result: ProjectResponse = await requestService.request(
			"project/retrieve",
			"GET",
			{}
		);
		return result;
	};
}
