import { formatProject } from "modules/utils/project";
import { setProjectOntologies } from "store/ontology/actions";
import { setProjects } from "store/project/project-actions";
import { RetrieveProjectApi } from "../api/retrieve-project.api.service";

export class RetrieveProjectStore extends RetrieveProjectApi {
	/**
	 * Retrieve all project owned by user
	 * @returns null
	 */
	public retrieveProjects = async () => {
		const result = await this.retrieveProjectsApi();
		if (result.projects && Array.isArray(result.projects)) {
			setProjects(
				result.projects.map((_project) => {
					const { ontology, project } = formatProject(_project);
					if (ontology) {
						setProjectOntologies([ontology]);
					}
					return project;
				})
			);
		}
	};
}
