import { Dataset } from "modules/types/dataset";
import { formatBody, formatRoute } from "modules/utils/ressource";
import { Project } from "modules/types/project";
import { Dictionary } from "modules/types/dictionary";
import { Campaign } from "modules/types/annotation-campaign";
import { RequestService } from "services/request.service";
import { Ontology } from "modules/types/ontology";
import { Pattern } from "modules/types/pattern";
import { RessourceType } from "modules/types/global";
import { Member } from "modules/types/group";

export class GroupViewApi extends RequestService {
	/**
	 * Retrieve members for all user groups
	 * @returns
	 */
	public retrieveAllGroupsMembersApi = async (): Promise<{
		groupMembers: {
			groupId: string;
			members: Member[];
		}[];
	}> => {
		const result = await this.request(
			"group/retrieve-groups-members",
			"POST",
			{}
		);
		return result;
	};
	/**
	 * Retrieve members from group id
	 * @param groupId
	 * @returns
	 */
	public retrieveMembersApi = async (groupId: string) => {
		const result = await this.request("group/retrieve-members", "POST", {
			groupId,
		});
		return result;
	};

	/**
	 * Retrieve data from dynamic ids
	 * @param dataIds
	 * @returns
	 */
	public retrieveDataByIdsApi = async (
		ressourceType: RessourceType,
		dataIds: string[]
	) =>
		await this.request(
			`${formatRoute(ressourceType)}/get-by-ids`,
			"POST",
			formatBody([{ value: dataIds, ressourceType, key: "Ids" }])
		);

	/**
	 * Retrieve campaigns from ids
	 * @param campaignIds
	 * @returns
	 */
	// public retrieveCampaignsApi = async (campaignIds: string[]) =>
	// 	await Promise.all(
	// 		campaignIds.map(
	// 			async (id) =>
	// 				await this.request(
	// 					`annotation-campaign/getById?annotationCampaignId=${id}`,
	// 					"GET",
	// 					{}
	// 				).then((result) => result.annotationCampaign as Campaign)
	// 		)
	// 	);

	/**
	 * Retrieve all campaigns
	 * @param campaignIds
	 * @returns
	 */
	public retrieveCampaignsApi = async (campaignIds: string[]) =>
		await this.request(`annotation-campaign/retrieve`, "GET", {}).then(
			(result) => result.annotate_campaigns as Campaign[]
		);

	/**
	 * Retrieve dictionaries from ids
	 * @param dictionaryIds
	 * @returns
	 */
	// public retrieveDictionariesApi = async (dictionaryIds: string[]) =>
	// 	await Promise.all(
	// 		dictionaryIds.map(
	// 			async (id) =>
	// 				await this.request(
	// 					`dictionary/getById?dictionaryId=${id}`,
	// 					"GET",
	// 					{}
	// 				).then((result) => result.dictionary as Dictionary)
	// 		)
	// 	);

	/**
	 * Retrieve all dictionaries
	 * @param dictionaryIds
	 * @returns
	 */
	public retrieveDictionariesApi = async (dictionaryIds: string[]) =>
		await this.request(`dictionary/retrieve`, "GET", {}).then(
			(result) => result.dictionaries as Dictionary[]
		);

	/**
	 * Retrieve projects from ids
	 * @param projectIds
	 * @returns
	 */
	// public retrieveProjectsApi = async (projectIds: string[]) =>
	// 	await Promise.all(
	// 		projectIds.map(
	// 			async (id) =>
	// 				await this.request(
	// 					`project/getById?projectId=${id}`,
	// 					"GET",
	// 					{}
	// 				).then((result) => result.project as Project)
	// 		)
	// 	);

	/**
	 * Retrieve all projects
	 * @param projectIds
	 * @returns
	 */
	public retrieveProjectsApi = async (projectIds: string[]) =>
		await this.request(`project/retrieve`, "GET", {}).then(
			(result) => result.projects as Project[]
		);

	/**
	 * Retrieve ontology from ids
	 * @param ontologyIds
	 * @returns
	 */
	// public retrieveOntologiesApi = async (ontologyIds: string[]) =>
	// 	await Promise.all(
	// 		ontologyIds.map(
	// 			async (id) =>
	// 				await this.request(
	// 					`ontology/getById?ontologyId=${id}`,
	// 					"GET",
	// 					{}
	// 				).then((result) => result.ontology as Ontology)
	// 		)
	// 	);

	/**
	 * Retrieve all ontologies
	 * @param ontologyIds
	 * @returns
	 */
	public retrieveOntologiesApi = async (ontologyIds: string[]) =>
		await this.request(`knowledge/retrieve`, "GET", {}).then(
			(result) => result.knowledges as Ontology[]
		);

	/**
	 * Retrieve datasets from ids
	 * @param dataSetIds
	 * @returns
	 */
	// public retrieveDatasetsApi = async (dataSetIds: string[]) =>
	// 	await Promise.all(
	// 		dataSetIds.map(
	// 			async (id) =>
	// 				await this.request(
	// 					`data-set/getById?datasetId=${id}`,
	// 					"GET",
	// 					{}
	// 				).then((result) => result.dataset as Dataset)
	// 		)
	// 	);

	/**
	 * Retrieve all datasets
	 * @param dataSetIds
	 * @returns
	 */
	public retrieveDataSetsApi = async (dataSetIds: string[]) =>
		await this.request(`data-set/retrieve-many`, "GET", {}).then(
			(result) => result.dataSets as Dataset[]
		);

	/**
	 * Retrieve patterns from pattern ids
	 * @param patternIds
	 * @returns
	 */
	public retrievePatternsApi = async (
		ressourceType: RessourceType,
		patternIds: string[]
	) => {
		if (!patternIds.length) return [];
		return await this.request(
			`pattern/get-by-ids`,
			"POST",
			formatBody([{ value: patternIds, ressourceType, key: "Ids" }])
		).then((result) => result.patterns as Pattern[]);
	};
}
