import { PayloadAction } from "@reduxjs/toolkit";
import { AnnotationItemV2 } from "modules/types/annotation";
import {
	AnnotationItemReducer,
	ByCampaignConfigItem,
} from "modules/types/annotation-item";

/**
 * TODO: Check return value in list
 * @param state
 * @param action
 * @returns
 */
export const setAnnotationItemsByCampaign = (
	state: AnnotationItemReducer,
	action: PayloadAction<{
		list: AnnotationItemV2[];
		count: number;
		config: ByCampaignConfigItem;
	}>
) => {
	const { list, config, count } = action.payload;
	let byCampaign = { ...state.byCampaign };
	let map = { ...state.map };

	let annotationItemIds = list.map((item, i) => {
		map[item._id] = item;
		return item._id;
	});

	byCampaign[config.annotationCampaignId] = {
		config,
		list: annotationItemIds,
		count,
	};

	return {
		...state,
		byCampaign,
		map,
		init: true,
	};
};

export const deleteAnnotationItemsByCampaign = (
	state: AnnotationItemReducer,
	action: PayloadAction<{ annotationCampaignId: string }>
) => {
	let byCampaign = { ...state.byCampaign };
	delete byCampaign[action.payload.annotationCampaignId];

	return {
		...state,
		byCampaign,
	};
};

export const resetAnnotationItems = (
	state: AnnotationItemReducer,
	action: PayloadAction<{ annotationItems: AnnotationItemV2[] }>
): AnnotationItemReducer => {
	let map: Record<string, AnnotationItemV2> = {};
	for (let annotationItem of action.payload.annotationItems)
		map[annotationItem?._id] = annotationItem;
	return {
		...state,
		map,
		init: true,
	};
};

export const setAnnotationItems = (
	state: AnnotationItemReducer,
	action: PayloadAction<{ annotationItems: AnnotationItemV2[] }>
): AnnotationItemReducer => {
	let map: Record<string, AnnotationItemV2> = { ...state.map };
	for (let annotationItem of action.payload.annotationItems)
		map[annotationItem?._id] = {
			...map[annotationItem?._id],
			...annotationItem,
		};
	return {
		...state,
		map,
		init: true,
	};
};

export const deleteAnnotationItems = (
	state: AnnotationItemReducer,
	action: PayloadAction<{ annotationItemIds: string[] }>
) => {
	let annotationItemMap: Record<string, AnnotationItemV2> = { ...state.map };
	for (let annotationItemId of action.payload.annotationItemIds)
		delete annotationItemMap[annotationItemId];

	return {
		...state,
		map: annotationItemMap,
	};
};
