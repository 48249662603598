import { Group } from "modules/types/group";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";
import { setGroups } from "store/group/group-actions";
import { RetrieveGroupApi } from "../api/retrieve-group.api.service";

export class RetrieveGroupStore extends RetrieveGroupApi {
	/**
	 * Retrieve all group owned by user
	 * @returns null
	 */
	public retrieveGroups = async () => {
		const result = await this.retrieveGroupsApi();
		if (result?.groups)
			setGroups(formatKnowledgeBeforeRedux(result.groups) as Group[]);
	};
}
