import { Suggestion } from "modules/types/suggestion";
/*eslint no-unused-vars:0*/
import { store } from "store/store";

export const setSuggestions = (suggestions: Suggestion[]) => {
	return store.dispatch({
		type: "SET_SUGGESTIONS",
		payload: suggestions,
	});
};

export const clearRessourceSuggestions = (ressourceIds: string[]) => {
	return store.dispatch({
		type: "CLEAR_RESSOURCE_SUGGESTIONS",
		payload: ressourceIds,
	});
};
