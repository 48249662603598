import reducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const middleware = [thunk];

export const store = createStore(
	reducer,
	process.env["NODE_ENV"] === "development"
		? composeWithDevTools(applyMiddleware(...middleware))
		: applyMiddleware(...middleware)
);
