import { setSuggestions } from "store/suggestion/suggestion-actions";
import { RetrieveSuggestionApi } from "../api/retrieve-suggestion.api.service";

export class RetrieveSuggestionsStore extends RetrieveSuggestionApi {
	/**
	 * Retrieve all suggestion owned by user
	 * @returns null
	 */
	public retrieveSuggestions = async () => {
		const result = await this.retrieveSuggestionsApi();
		setSuggestions(result.suggestions);
	};
}
