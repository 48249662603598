import { TrainingsResponse } from "modules/types/training";
import { setTrainings } from "store/training/actions/training-actions";
import { RetrieveTrainingApi } from "../api/retrieve-training.api.service";

export class RetrieveTrainingStore extends RetrieveTrainingApi {
	/**
	 * Retrieve all training owned by user
	 * @returns null
	 */
	public retrieveTrainings = async (projectIds: string[] = []) => {
		const result: TrainingsResponse | null =
			await this.retrieveTrainingsApi(projectIds);
		if (result && result.annotationTrainings) {
			setTrainings(result.annotationTrainings);
			return result.annotationTrainings;
		}
		return false;
	};
}
