/*eslint no-unreachable: "off"*/
import { OntologyReducer } from "modules/types/ontology";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./methods";
import {
	OntologiesActionType,
	OntologyActionType,
} from "modules/types/ontology/store";

const initialState: OntologyReducer = {
	init: false,
	ontologyMap: {},
	classPropertyMap: {},
};

export const ontologyReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "RESET_ONTOLOGIES":
			return methods.resetOntologies(
				state,
				action as OntologiesActionType
			);
		case "SET_ONTOLOGIES":
			return methods.setOntologies(state, action as OntologiesActionType);
		case "SET_PROJECT_ONTOLOGIES":
			return methods.setProjectOntologies(
				state,
				action as OntologiesActionType
			);
		case "ADD_ONTOLOGY":
			return methods.addOntology(state, action as OntologyActionType);
		case "UPDATE_ONTOLOGY":
			return methods.updateOntology(state, action as OntologyActionType);
		case "UPDATE_ONTOLOGY_OWNER": {
			return methods.updateOntologyOwnerType(state, action as any);
		}
		case "UPDATE_ONTOLOGY_DIFF":
			return methods.updateOntologyDiff(
				state,
				action as OntologyActionType
			);
		case "DELETE_ONTOLOGY":
			return methods.deleteOntology(state, action as any);
		case "ADD_KNOWLEDGE_CARD_TO_ONTOLOGY":
			return methods.addKnowledgeCardToOntology(state, action as any);
		case "SET_SELECTED_CARD_TO_ONTOLOGY":
			return methods.setSelectedCardIdsToOntology(state, action as any);
		case "UPDATE_KNOWLEDGE_CARDS":
			return methods.updateKnowledgeCards(state, action as any);
		case "UPDATE_KNOWLEDGE_CARD_SUGGESTIONS":
			return methods.updateKnowledgeCardSuggestion(state, action as any);
		case "DELETE_KNOWLEDGE_CARDS":
			return methods.deleteKnowledgeCards(state, action as any);
		case "SET_PROPERTIES":
			return methods.setClassProperties(state, action as any);
		case "ADD_PROPERTY":
			return methods.addClassProperty(state, action as any);
		case "UPDATE_PROPERTY":
			return methods.updateClassProperty(state, action as any);
		case "UPDATE_PROPERTIES":
			return methods.updateClassProperties(state, action as any);
		case "DELETE_PROPERTY":
			return methods.deleteClassProperty(state, action as any);
		case "SET_SELECTED_PROPERTIES":
			return methods.setSelectedProperties(state, action as any);
		default:
			break;
	}
	return state;
};
