import { RequestService } from "services/request.service";
import { PatternResponse } from "modules/types/pattern";

const requestService: RequestService = new RequestService();

export class RetrievePatternApi extends RequestService {
	public retrievePatternsApi = async () => {
		// const result: PatternResponse = await requestService.request(
		// 	"pattern/retrieve",
		// 	"GET",
		// 	{}
		// );
		const result = await this.request(
			`/pattern/retrieve?skip=0&limit=0`,
			"GET",
			{}
		);
		return result;
	};
}
