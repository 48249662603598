import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import Lottie from "lottie-react";
import LettriaLoader from "./lettria-loader.json";
import "./spinner.style.less";

type SpinnerProps = {
	text?: string;
	noMarginTop?: boolean;
	noPadding?: boolean;
	header?: boolean;
	fontSize?: number;
	title?: string;
	lettriaLogo?: boolean;
};

export const Spinner = (props: SpinnerProps) => {
	return (
		<>
			<div
				className="loader-container"
				style={{
					width: "100%",
					paddingTop: props.noPadding
						? 0
						: props.noMarginTop
						? 40
						: "calc(50vh - 170px)",
				}}>
				{!props.lettriaLogo ? (
					<div className="spinner-container">
						<Spin
							style={{ margin: "auto", display: "block" }}
							indicator={
								<LoadingOutlined
									style={{
										fontSize: 24,
									}}
									spin
								/>
							}
						/>
					</div>
				) : (
					<Lottie
						animationData={LettriaLoader}
						style={{ width: 140, height: 140, margin: "auto" }}
					/>
				)}
				<Typography style={{ textAlign: "center", marginTop: 24 }}>
					{props.title ? (
						<Typography.Text strong>{props.title}</Typography.Text>
					) : null}
					{props.text ? (
						<Typography.Paragraph
							style={{ marginTop: 12 }}
							type="secondary">
							{props.text}
						</Typography.Paragraph>
					) : null}
				</Typography>
			</div>
		</>
	);
};
