import { TokenMap, Token, TokenReducer } from "modules/types/token";
import { PayloadAction } from "@reduxjs/toolkit";

export const resetProjectTokens = (
	state: TokenReducer,
	action: PayloadAction<{ projectId: string; tokens: Token[] }>
): TokenReducer => {
	let map: TokenMap = {};
	map[action.payload.projectId] = action.payload.tokens;

	return {
		...state,
		map,
		init: true,
	};
};

export const setProjectTokens = (
	state: TokenReducer,
	action: PayloadAction<{ projectId: string; tokens: Token[] }>
): TokenReducer => {
	let map: TokenMap = { ...state.map };
	map[action.payload.projectId] = {
		...map[action.payload.projectId],
		...action.payload.tokens,
	};
	return {
		...state,
		map,
		init: true,
	};
};

// export const addToken = (state: TokenReducer, action: PayloadAction<Token>) => {
// 	let tokensMap: TokenMap = { ...state.map };
// 	tokensMap[action.payload?._id] = action.payload;
// 	return {
// 		...state,
// 		map: tokensMap,
// 	};
// };

// export const updateToken = (
// 	state: TokenReducer,
// 	action: PayloadAction<Token>
// ) => {
// 	let tokensMap: TokenMap = { ...state.map };
// 	tokensMap[action.payload?._id] = action.payload;
// 	return {
// 		...state,
// 		map: tokensMap,
// 	};
// };

// export const deleteToken = (
// 	state: TokenReducer,
// 	action: PayloadAction<Token>
// ) => {
// 	let tokensMap: TokenMap = { ...state.map };
// 	delete tokensMap[action.payload._id];

// 	return {
// 		...state,
// 		map: tokensMap,
// 	};
// };
