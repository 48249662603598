import { Notification } from "modules/types/notification";
import { addNotification } from "store/notification/notification-actions";

export const intializePushHandler = () => {
	navigator.serviceWorker.addEventListener("message", (event) => {
		if (event.data.type === "NOTIFICATION") {
			addNotification(event.data.payload as Notification);
			console.log("Notification received: ", event.data.payload);
		}
		// if (event.data.type === "PROJECT") {
		// 	addNotification(event.data.payload as Project);
		// }
	});
};
