import { AnyAction } from "@reduxjs/toolkit";
import { AnnotationDataReducer } from "modules/types/annotation-data";
import * as methods from "./annotation-data-methods";

const initialState: AnnotationDataReducer = {
	init: false,
	byCampaign: {},
	map: {},
};

export const annotationDataReducer = (
	state = initialState,
	action: AnyAction
): AnnotationDataReducer => {
	switch (action.type) {
		case "SET_ANNOTATION_DATAS_BY_CAMPAIGN": {
			return methods.setAnnotationDatasByCampaign(state, action as any);
		}
		case "DELETE_ANNOTATION_DATAS_BY_CAMPAIGN": {
			return methods.deleteAnnotationDatasByCampaign(
				state,
				action as any
			);
		}
		case "RESET_ANNOTATION_DATAS": {
			return methods.resetAnnotationDatas(state, action as any);
		}
		case "SET_ANNOTATION_DATAS": {
			return methods.setAnnotationDatas(state, action as any);
		}
		case "DELETE_ANNOTATION_DATAS": {
			return methods.deleteAnnotationDatas(state, action as any);
		}
		default: {
			break;
		}
	}
	return state;
};

export default annotationDataReducer;
