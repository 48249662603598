/*eslint no-unreachable: "off"*/
import { DatasetReducer } from "modules/types/dataset";
import { AnyAction } from "@reduxjs/toolkit";
import {
	DatasetsActionType,
	DatasetActionType,
} from "modules/types/dataset/store";
import * as methods from "./dataset-methods";

const initialState: DatasetReducer = {
	init: false,
	datasetMap: {},
	assetMap: {},
};

export const datasetReducer = (
	state = initialState,
	action: AnyAction
): DatasetReducer => {
	switch (action.type) {
		case "RESET_DATASETS":
			return methods.resetDatasets(state, action as DatasetsActionType);

		case "SET_DATASETS":
			return methods.setDatasets(state, action as DatasetsActionType);

		case "ADD_DATASET":
			return methods.addDataset(state, action as DatasetActionType);

		case "UPDATE_DATASET":
			return methods.updateDataset(state, action as DatasetActionType);

		case "UPDATE_DATASET_OWNER":
			return methods.updateDatasetOwnerType(state, action as any);

		case "DELETE_DATASET":
			return methods.deleteDataset(state, action as any);

		case "RESET_ASSETS":
			return methods.resetAssets(state, action as any);

		case "SET_ASSETS":
			return methods.setAssets(state, action as any);

		case "DELETE_ASSET":
			return methods.deleteAssets(state, action as any);
	}
	return state;
};
