/*eslint no-unreachable: "off"*/
import { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { BooleanActionType } from "modules/types/global/store";
import { SuggestionReducer } from "modules/types/suggestion";
import {
	SuggestionsActionType,
	SuggestionActionType,
	StatusActionType,
} from "modules/types/suggestion/store";
import * as methods from "./suggestion-methods";

const initialState: SuggestionReducer = {
	init: false,
	byRessource: {},
};

export const suggestionReducer = (
	state = initialState,
	action: AnyAction
): SuggestionReducer => {
	switch (action.type) {
		case "SET_SUGGESTIONS": {
			return methods.setSuggestions(
				state,
				action as SuggestionsActionType
			);
			break;
		}
		case "CLEAR_RESSOURCE_SUGGESTIONS": {
			return methods.clearRessourceSuggestions(
				state,
				action as PayloadAction<string[]>
			);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
