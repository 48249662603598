import { AuthStore } from "./store/auth.store.service";
import { Credentials as SignupCredentials } from "../../signup/signup.component";
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserAttribute,
	CognitoUserPool,
} from "amazon-cognito-identity-js";

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

const poolData = {
	UserPoolId: `${userPoolId}`,
	ClientId: `${clientId}`,
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

export class AuthService extends AuthStore {
	/**
	 * Service function that call sign in thunks
	 * @param email
	 * @param password
	 * @param _rememberMe
	 */
	public signIn = async (
		email: string,
		password: string,
		rememberMe: boolean
	) => {
		await this.signInThunk(email, password, rememberMe);
	};

	public googleSignIn = async () => {
		await this.googleSignInThunk();
	};
	
	public signUp = async (credentials: SignupCredentials) => {
		await this.signUpThunk(credentials);
	};

	/**
	 * Service function that call sign out thunks
	 */
	public signOut = async () => {
		await this.signOutThunk();
	};

	/**
	 * Service function that call sign out thunks
	 */
	public signInCognito = async (code: string) => {
		await this.signInCognitoThunk(code);
	};

	/**
	 * Service function that call sign out thunks
	 */
	public restoreSession = async () => {
		await this.restoreSessionThunk();
	};

	public getCognitoUser(username: string) {
		const userData = {
			Username: username,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);

		return cognitoUser;
	}

	// public signUpUserWithEmail(
	// 	username: string,
	// 	email: string,
	// 	password: string
	// ) {
	// 	return new Promise(function (resolve, reject) {
	// 		const attributeList = [
	// 			new CognitoUserAttribute({
	// 				Name: "email",
	// 				Value: email,
	// 			}),

	// 			new CognitoUserAttribute({
	// 				Name: "name",
	// 				Value: "Sidox",
	// 			}),

	// 			new CognitoUserAttribute({
	// 				Name: "family_name",
	// 				Value: "MEkhiche",
	// 			}),
	// 		];

	// 		userPool.signUp(
	// 			username,
	// 			password,
	// 			attributeList,
	// 			[],
	// 			function (err, res) {
	// 				if (err) {
	// 					reject(err);
	// 				} else {
	// 					resolve(res);
	// 				}
	// 			}
	// 		);
	// 	}).catch((err) => {
	// 		throw err;
	// 	});
	// }

	public signInWithEmail(username: string, password: string) {
		return new Promise(function (resolve, reject) {
			const authenticationData = {
				Username: username,
				Password: password,
			};
			const authenticationDetails = new AuthenticationDetails(
				authenticationData
			);

			const userData = {
				Username: username,
				Pool: userPool,
				//ClientId: clientId as string,
			};
			const currentUser = new CognitoUser(userData);

			currentUser.authenticateUser(authenticationDetails, {
				onSuccess: function (res: any) {
					resolve(res);
				},
				onFailure: function (err: any) {
					reject(err);
				},
			});
		}).catch((err) => {
			throw err;
		});
	}

	public resendPasswordCode(username: string) {
		return new Promise(function (resolve, reject) {
			const userData = {
				Username: username,
				Pool: userPool,
			};
			console.log("resendPasswordCode", userData);
			const currentUser = new CognitoUser(userData);

			currentUser.forgotPassword({
				onSuccess: function (res) {
					console.log("resendPasswordCode success", res);
					resolve(res);
				},
				onFailure: function (err) {
					console.log(err);
					reject(err);
				},
			});
		}).catch((err) => {
			throw err;
		});
	}

	public resendConfirmationCode(username: string) {
		return new Promise(function (resolve, reject) {
			const userData = {
				Username: username,
				Pool: userPool,
				//ClientId: clientId as string,
			};
			const cognitoUser = new CognitoUser(userData);

			const params = {
				Username: username,
				ClientId: clientId as string,
			};

			cognitoUser.resendConfirmationCode(function (err, data) {
				if (err) console.log(err, err.stack); // an error occurred
				else console.log(data); // successful response
			}, params);
			// cognitoUser.resendConfirmationCode(userData);
		}).catch((err) => {
			throw err;
		});
	}

	public verifyCode = async (username: string, code: string) => {
		return new Promise(function (resolve, reject) {
			// const cognitoUser = getCognitoUser(username)
			const userData = {
				Username: username,
				Pool: userPool,
			};
			const cognitoUser = new CognitoUser(userData);
			cognitoUser.confirmRegistration(code, true, function (err, result) {
				if (err) {
					reject(err);
				} else {
					resolve(result);
				}
			});
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	};

	public forgotPassword(username: string, code: string, password: string) {
		return new Promise(function (resolve, reject) {
			const userData = {
				Username: username,
				Pool: userPool,
			};
			const cognitoUser = new CognitoUser(userData);

			if (!cognitoUser) {
				reject(`could not find ${username}`);
				return;
			}

			cognitoUser.confirmPassword(code, password, {
				onSuccess: function () {
					console.log("Password confirmed!");
					resolve("password updated");
				},
				onFailure: function (err: any) {
					console.log(err);
					reject(err);
				},
			});
		});
	}

	public getSelf = async () => await this.getSelfStore();
}
