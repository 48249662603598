/* eslint-disable no-unreachable */
import { AnyAction } from "@reduxjs/toolkit";
import { StringActionType } from "modules/types/global/store";
import { WorkspaceReducer } from "modules/types/workspace";
import { setWorkspace } from "./workspace-methods";

const initialState = {
	init: false,
	name: "",
};

export const workspaceReducer = (
	state = initialState,
	action: AnyAction
): WorkspaceReducer => {
	switch (action.type) {
		case "SET_WORKSPACE": {
			return setWorkspace(state, action as StringActionType);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
