import { setLoggedIn, setUser } from "store/user/user-actions";
import { AuthApi } from "../api/auth.api.service";
import { RoutesService } from "views/routes/services/routes.service";
import { Credentials as SignupCredentials } from "../../../signup/signup.component";
import { CognitoTokenResponse, CognitoUserSession } from "modules/types/user";
import registerNotificationServiceWorker from "../register-notification-service-worker";
export class AuthStore extends AuthApi {
	/**
	 * Thunks that call a dispatch to make api call
	 * @param email
	 * @param password
	 * @returns response from sign in api request {token: string, user: UserData}
	 */

	public signInThunk = async (
		email: string,
		password: string,
		rememberMe: boolean
	) => {
		const tokenResponse = await this.signInApi(email, password);
		if (tokenResponse?.accessToken)
			localStorage.setItem(
				"LettriaAccessToken",
				tokenResponse.accessToken?.jwtToken
			);
		if (tokenResponse?.idToken)
			localStorage.setItem(
				"LettriaIdToken",
				tokenResponse.idToken.jwtToken
			);
		if (tokenResponse?.refreshToken)
			localStorage.setItem(
				"LettriaRefreshToken",
				tokenResponse.refreshToken.token
			);
		if (tokenResponse?.idToken.jwtToken) setLoggedIn(true);

		const getSelfResponse = await this.getSelfApi(
			tokenResponse?.idToken.jwtToken!
		);
		if (getSelfResponse?._id) {
			setUser(getSelfResponse);
		}
	};

	public signUpThunk = async (credentials: SignupCredentials) => {
		const tokenResponse = await this.signUpApi(
			credentials.firstName!,
			credentials.lastName!,
			credentials.email!,
			credentials.password!
		);
	};
	public googleSignInThunk = async () => {
		const tokenResponse = await this.googleSignInApi();
	};

	/**
	 * Sign out function that remove token from localStorage
	 */
	public signOutThunk = async () => {
		const routesService: RoutesService = new RoutesService();

		localStorage.removeItem("LettriaRefreshToken");
		localStorage.removeItem("LettriaIdToken");
		localStorage.removeItem("LettriaAccessToken");
		window.location.reload();

		return true;
	};

	public signInCognitoThunk = async (code: string) => {
		try {
			const tokenResponse = await this.signInCognitoApi(code);

			if (tokenResponse?.access_token)
				localStorage.setItem(
					"LettriaAccessToken",
					tokenResponse.access_token
				);
			if (tokenResponse?.id_token)
				localStorage.setItem("LettriaIdToken", tokenResponse.id_token);
			if (tokenResponse?.refresh_token)
				localStorage.setItem(
					"LettriaRefreshToken",
					tokenResponse.refresh_token
				);
			if (tokenResponse?.id_token) setLoggedIn(true);
			const getSelfResponse = await this.getSelfApi(
				tokenResponse?.id_token!
			);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
			}
		} catch (e) {
			console.error(e);
		}

		return true;
	};

	public restoreSessionThunk = async () => {
		try {
			let currentIdToken = localStorage.getItem("LettriaIdToken");
			if (!currentIdToken) {
				setLoggedIn(false);
				return true;
			}
			let tokenResponse: CognitoUserSession =
				await this.restoreSessionApi();
			if (tokenResponse?.accessToken)
				localStorage.setItem(
					"LettriaAccessToken",
					tokenResponse.accessToken.jwtToken
				);
			if (tokenResponse.idToken)
				localStorage.setItem(
					"LettriaIdToken",
					tokenResponse.idToken.jwtToken
				);
			if (tokenResponse?.refreshToken)
				localStorage.setItem(
					"LettriaRefreshToken",
					tokenResponse.refreshToken.token
				);
			if (tokenResponse?.idToken) setLoggedIn(true);

			registerNotificationServiceWorker();
			const getSelfResponse = await this.getSelfApi(
				tokenResponse.idToken.jwtToken!
			);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
			}
		} catch (error: unknown) {
			// alert(error);
			setLoggedIn(false);
		}
	};

	protected getSelfStore = async () => {
		const idToken = localStorage.getItem("LettriaIdToken");
		if (idToken) {
			const getSelfResponse = await this.getSelfApi(idToken);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
				return getSelfResponse;
			}
		}
		return false;
	};
}
