import React, { useState, useContext } from "react";

import {
	Form,
	Grid,
	Input,
	Checkbox,
	Button,
	Layout,
	Row,
	Col,
	Alert,
	Divider,
	Space,
	Typography,
	Card,
} from "antd";
import {
	ArrowLeftOutlined,
	LockOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useValidUsername } from "../auth/hooks/useAuthHooks";
import "./styles/forgot.style.less";
import { SendCodeForm } from "./components/send-code-form.components";
import { ResetPasswordForm } from "./components/reset-password-form.components";

export interface Credentials {
	name: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	password: string | null;
	confirmPassword: string | null;
}

export default function ForgotPassword() {
	const { username, setUsername } = useValidUsername("");
	const [codeIsSend, setCodeIsSend] = useState(false);
	const navigate = useNavigate();
	return (
		<Row justify="center" className="forgot-container">
			<Col span={12} className="left-column" style={{ width: "58%" }}>
				<img
					className="lettria-logo"
					alt=""
					src="/images/lettria.svg"
					onClick={() => navigate("/")}
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/unlock-text.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/purposes.svg"
					style={{ marginLeft: "50%" }}
				/>
				<img
					alt=""
					src="/images/icons/clients.svg"
					style={{ bottom: "48px", position: "absolute" }}
				/>
			</Col>

			<Col span={12} className="right-column">
				<p className="no-card">
					<ArrowLeftOutlined
						style={{ color: "#6b4eb6", marginRight: "10px" }}
					/>
					<Link to="/login" className="no-card">
						Back to Login
					</Link>
				</p>
				{codeIsSend ? (
					<ResetPasswordForm username={username} />
				) : (
					<SendCodeForm
						setCodeIsSend={setCodeIsSend}
						setUsername={setUsername}
					/>
				)}
			</Col>
		</Row>
	);
}
