import { AnyAction } from "@reduxjs/toolkit";
import { AnnotationItemReducer } from "modules/types/annotation-item";
import * as methods from "./annotation-item-methods";

const initialState: AnnotationItemReducer = {
	init: false,
	byCampaign: {},
	map: {},
};

export const annotationItemReducer = (
	state = initialState,
	action: AnyAction
): AnnotationItemReducer => {
	switch (action.type) {
		case "SET_ANNOTATION_ITEMS_BY_CAMPAIGN": {
			return methods.setAnnotationItemsByCampaign(state, action as any);
		}
		case "DELETE_ANNOTATION_ITEMS_BY_CAMPAIGN": {
			return methods.deleteAnnotationItemsByCampaign(
				state,
				action as any
			);
		}
		case "RESET_ANNOTATION_ITEMS": {
			return methods.resetAnnotationItems(state, action as any);
		}
		case "SET_ANNOTATION_ITEMS": {
			return methods.setAnnotationItems(state, action as any);
		}
		case "DELETE_ANNOTATION_ITEMS": {
			return methods.deleteAnnotationItems(state, action as any);
		}
		default: {
			break;
		}
	}
	return state;
};

export default annotationItemReducer;
