import { PayloadAction } from "@reduxjs/toolkit";
import {
	Suggestion,
	SuggestionMap,
	SuggestionReducer,
} from "modules/types/suggestion";

const addOrUpdateSuggestion = (list: Suggestion[], suggestion: Suggestion) => {
	const index = list.findIndex((item) => item._id === suggestion._id);
	if (index === -1) {
		list.push(suggestion);
	} else {
		list[index] = suggestion;
	}
	return list;
};

export const setSuggestions = (
	state: SuggestionReducer,
	action: PayloadAction<Suggestion[]>
): SuggestionReducer => {
	let byRessource: SuggestionMap = { ...state.byRessource };
	action.payload.forEach((suggestion: Suggestion) => {
		let { ressourceId, ressourceType } = suggestion;
		byRessource[ressourceId] = addOrUpdateSuggestion(
			byRessource[ressourceId] || [],
			suggestion
		);
	});
	return {
		...state,
		byRessource,
		init: true,
	};
};

export const clearRessourceSuggestions = (
	state: SuggestionReducer,
	action: PayloadAction<string[]>
): SuggestionReducer => {
	let byRessource: SuggestionMap = { ...state.byRessource };

	for (let ressourceId of action.payload) {
		delete byRessource[ressourceId];
	}

	return {
		...state,
		byRessource,
	};
};
