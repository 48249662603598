import { combineReducers } from "redux";
import { userReducer } from "./user/user-reducer";
import { subscriptionReducer } from "./subscription/subscription-reducer";
import { notificationReducer } from "./notification/notification-reducer";
import { suggestionReducer } from "./suggestion/suggestion-reducer";
import { workspaceReducer } from "./workspace/workspace-reducer";
import { projectReducer } from "./project/project-reducer";
import { dictionaryReducer } from "./dictionary/dictionary-reducer";
import { groupReducer } from "./group/group-reducer";
import { annotationCampaignReducer } from "./annotation-campaign/annotation-campaign-reducer";
import { annotationDataReducer } from "./annotation-data/annotation-data-reducer";
import { annotationItemReducer } from "./annotation-item/annotation-item-reducer";
import { ontologyReducer } from "./ontology/ontology-reducer";
import { patternReducer } from "./pattern/pattern-reducer";
import { analyticReducer } from "./analytic/analytic-reducer";
import { tokenReducer } from "./token/token-reducer";
import { datasetReducer } from "./dataset/dataset-reducer";
import { fileStorageReducer } from "./file-storage/file-storage-reducer";
import { flowReducer } from "./flow/flow-reducer";
import { trainingReducer } from "./training/training-reducer";

export default combineReducers({
	/**
	 * USER
	 */
	user: userReducer,

	/**
	 * NLP
	 */
	dataset: datasetReducer,
	dictionary: dictionaryReducer,
	fileStorage: fileStorageReducer,
	group: groupReducer,
	project: projectReducer,
	token: tokenReducer,
	training: trainingReducer,

	/**
	 * ANNOTATION
	 */
	annotationCampaign: annotationCampaignReducer,
	annotationData: annotationDataReducer,
	annotationItem: annotationItemReducer,

	/**
	 * STRUCTURATION
	 */
	ontology: ontologyReducer,
	pattern: patternReducer,

	/**
	 * OTHER
	 */
	suggestion: suggestionReducer,
	analytic: analyticReducer,
	notification: notificationReducer,
	subscription: subscriptionReducer,
	workspace: workspaceReducer,
	flow: flowReducer,
});
