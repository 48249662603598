import { OwnerType } from "modules/types/user";
import {
	Campaign,
	EvolutionData,
	KpiData,
} from "modules/types/annotation-campaign";
import { store } from "store/store";

export const resetCampaigns = (campaigns: Campaign[]) => {
	return store.dispatch({
		type: "RESET_CAMPAIGNS",
		payload: campaigns,
	});
};

export const setCampaigns = (campaigns: Campaign[]) => {
	return store.dispatch({
		type: "SET_CAMPAIGNS",
		payload: campaigns,
	});
};

export const addCampaign = (campaign: Campaign) => {
	return store.dispatch({
		type: "ADD_CAMPAIGN",
		payload: campaign,
	});
};

export const updateCampaign = (campaign: Campaign) => {
	return store.dispatch({
		type: "UPDATE_CAMPAIGN",
		payload: campaign,
	});
};

export const updateCampaignOwnerType = (
	ressourceId: string,
	ownerType: OwnerType,
	groupId: string
) => {
	return store.dispatch({
		type: "UPDATE_CAMPAIGN_OWNER",
		payload: { ressourceId, ownerType, groupId },
	});
};

export const deleteCampaign = (annotationCampaignId: string) => {
	return store.dispatch({
		type: "DELETE_CAMPAIGN",
		payload: annotationCampaignId,
	});
};

export const setCampaignData = (annotationCampaignId: string, data: []) => {
	return store.dispatch({
		type: "SET_CAMPAIGN_DATA",
		payload: {
			annotationCampaignId,
			data,
		},
	});
};

export const deleteCampaignData = (annotationCampaignId: string) => {
	return store.dispatch({
		type: "DELETE_CAMPAIGN_DATA",
		payload: annotationCampaignId,
	});
};

export const setKpiData = (annotationCampaignId: string, kpiData: KpiData) => {
	return store.dispatch({
		type: "SET_KPI_DATA",
		payload: { annotationCampaignId, kpiData },
	});
};

export const setEvolutionData = (
	annotationCampaignId: string,
	evolutionData: EvolutionData
) => {
	return store.dispatch({
		type: "SET_EVOLUTION_DATA",
		payload: { annotationCampaignId, evolutionData },
	});
};
