import { TrainingOutputMap } from "./../../types/training/training-output.d";
import { CampaignMap } from "modules/types/annotation-campaign";
import { DatasetMap } from "modules/types/dataset";
import { DictionaryMap } from "modules/types/dictionary";
import { FileStorageMap } from "modules/types/file-storage";
import { RessourceType } from "modules/types/global";
import { GroupMap } from "modules/types/group";
import {
	ClassPropertyMap,
	KnowledgeCardMap,
	OntologyMap,
} from "modules/types/ontology";
import { PatternMap } from "modules/types/pattern";
import { ProjectMap } from "modules/types/project";
import { TrainingMap } from "modules/types/training";
import { WorkspaceType } from "modules/types/workspace";

/**
 * Retrurn the name of the key for the ressource ids array
 * @param ressourceType
 * @returns
 */
export const getRessourceIdsFromRessourceType = (
	ressourceType: RessourceType,
	inProject?: boolean
) => {
	switch (ressourceType) {
		case "annotation-campaign":
			return inProject && "annotationCampaignIds";
		// case "annotation-data":
		// 	return "annotationDataIds";
		// case "annotation-item":
		// 	return "annotationItemIds";
		case "training":
			return "annotationTrainingIds";
		case "training-output":
			return "annotationTrainingOutputIds";
		case "class-property":
			return "classPropertyIds";
		case "dataset":
			return "dataSetIds";
		case "dictionary":
			return "dictionaryIds";
		case "file-storage":
			return "fileStorageIds";
		case "group":
			return inProject && "groupIds";
		case "knowledge-card":
			return "knowledgeCardIds";
		case "ontology":
			return "ontologyIds";
		case "pattern":
			return "patternIds";
		case "project":
			return inProject && "projectIds";
		default:
			return false;
	}
};

/**
 * Return the name of the workspace for each ressource
 * @param ressourceType
 * @returns
 */
export const getWorkspaceFromRessource = (
	ressourceType: RessourceType
): WorkspaceType => {
	switch (ressourceType) {
		// case "annotation-data":
		// case "annotation-item":
		case "annotation-campaign":
			return "annotation";
		case "training":
			return "nlp";
		case "training-output":
			return "nlp";
		case "project":
		case "dictionary":
		case "file-storage":
		case "dataset":
		case "group":
			return "nlp";
		case "ontology":
		case "knowledge-card":
		case "class-property":
		case "pattern":
			return "structuration";
	}
};

export const getPathFromRessource = (ressourceType: RessourceType) =>
	`${getWorkspaceFromRessource(ressourceType)}/${ressourceType}/`;

export const getRessourceMapFromRessourceType = (
	ressourceType: RessourceType,
	map: {
		projectMap?: ProjectMap;
		campaignMap?: CampaignMap;
		trainingMap?: TrainingMap;
		trainingOutputMap?: TrainingOutputMap;
		dictionaryMap?: DictionaryMap;
		patternMap?: PatternMap;
		datasetMap?: DatasetMap;
		fileStorageMap?: FileStorageMap;
		ontologyMap?: OntologyMap;
		groupMap?: GroupMap;
		knowledgeCardMap?: KnowledgeCardMap;
		classPropertyMap?: ClassPropertyMap;
	}
) => {
	switch (ressourceType) {
		case "annotation-campaign":
			return map.campaignMap || {};
		case "training":
			return map.trainingMap || {};
		case "training-output":
			return map.trainingOutputMap || {};
		case "class-property":
			return map.classPropertyMap || {};
		case "dictionary":
			return map.dictionaryMap || {};
		case "dataset":
			return map.datasetMap || {};
		case "file-storage":
			return map.fileStorageMap || {};
		case "knowledge-card":
			return map.knowledgeCardMap || {};
		case "ontology":
			return map.ontologyMap || {};
		case "pattern":
			return map.patternMap || {};
		case "project":
			return map.projectMap || {};
		case "group":
			return map.groupMap || {};
		default:
			return {};
	}
};
