import {
	TrainingOutputsActionType,
	TrainingOutputIdsActionType,
} from "modules/types/training/store";
import { TrainingOutput } from "modules/types/training";
import { store } from "store/store";

export const resetTrainingOutputs = (
	trainingOutputs: TrainingOutput[]
): TrainingOutputsActionType =>
	store.dispatch({
		type: "RESET_TRAINING_OUTPUTS",
		payload: { trainingOutputs },
	});

export const setTrainingOutputs = (
	trainingOutputs: TrainingOutput[]
): TrainingOutputsActionType =>
	store.dispatch({
		type: "SET_TRAINING_OUTPUTS",
		payload: { trainingOutputs },
	});

export const deleteTrainingOutputs = (
	trainingOutputIds: string[]
): TrainingOutputIdsActionType =>
	store.dispatch({
		type: "DELETE_TRAINING_OUTPUTS",
		payload: { trainingOutputIds },
	});
