/*eslint no-unused-vars:0*/
import { ClassProperty } from "modules/types/ontology";
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "store/store";

export const setClassProperties = (
	classProperties: ClassProperty[]
): AnyAction => {
	return store.dispatch({
		type: "SET_PROPERTIES",
		payload: classProperties,
	});
};

export const addClassProperty = (
	ontologyId: string,
	classProperty: ClassProperty
): AnyAction => {
	return store.dispatch({
		type: "ADD_PROPERTY",
		payload: { ontologyId, classProperty },
	});
};

export const updateClassProperty = (
	classProperty: ClassProperty
): AnyAction => {
	return store.dispatch({
		type: "UPDATE_PROPERTY",
		payload: classProperty,
	});
};

export const updateClassProperties = (
	classProperties: ClassProperty[]
): AnyAction => {
	return store.dispatch({
		type: "UPDATE_PROPERTIES",
		payload: classProperties,
	});
};

export const deleteClassProperty = (
	ontologyId: string,
	classProperty: ClassProperty
): AnyAction => {
	return store.dispatch({
		type: "DELETE_PROPERTY",
		payload: { ontologyId, classProperty },
	});
};

export const setSelectedProperties = (
	ontologyId: string,
	classPropertyIds: string[]
): AnyAction => {
	return store.dispatch({
		type: "SET_SELECTED_PROPERTIES",
		payload: { ontologyId, classPropertyIds },
	});
};
