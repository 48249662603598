/* eslint-disable react/jsx-no-target-blank */
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Row, Col, Input, Tooltip, Space, Switch, Button } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { AuthService } from "views/public/auth/services/auth.service";
import "../styles/signup.style.less";

export interface Credentials {
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	password: string | null;
	confirmPassword: string | null;
}

export const SignupForm = () => {
	const authService: AuthService = new AuthService();

	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [cognitorErrorMessage, setCognitorErrorMessage] = useState<
		string | null
	>(null);
	const [emailError, setEmailError] = useState<string | null>(null);

	const [email, setEmail] = useState<string>("");
	const [terms, setTerms] = useState<boolean>(false);
	const [heightChar, setHeightChar] = useState<boolean>(false);
	const [lowerChar, setLowerChar] = useState<boolean>(false);
	const [upperChar, setUpperChar] = useState<boolean>(false);
	const [numberChar, setNumberChar] = useState<boolean>(false);

	const navigate = useNavigate();

	const validatePassword = (e: any) => {
		const password = e.target.value;
		if (password.length >= 8) {
			setHeightChar(true);
		} else {
			setHeightChar(false);
		}
		if (password.match(/[a-z]/)) {
			setLowerChar(true);
		} else {
			setLowerChar(false);
		}

		if (password.match(/[A-Z]/)) {
			setUpperChar(true);
		} else {
			setUpperChar(false);
		}

		if (password.match(/[0-9]/)) {
			setNumberChar(true);
		} else {
			setNumberChar(false);
		}
	};
	const validateEmail = (e: any) => {
		setEmailError(null);
		setEmail(e.target.value);
		setIsSubmit(false);
	};

	const onFinish = async (credentials: Credentials): Promise<void> => {
		setEmailError(null);
		if (!isSubmit) {
			setIsSubmit(true);
		}
		credentials.email = email;
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (!pattern.test(email)) {
			return setEmailError("Please enter a valid email");
		}
		try {
			if (
				credentials.email &&
				credentials.password &&
				credentials.password === credentials.confirmPassword
			) {
				await authService.signUp(credentials);
				localStorage.setItem("password", credentials.password);
				navigate("/verify/" + credentials.email);
			}
		} catch (error: any) {
			console.error(error);
			setCognitorErrorMessage(error.message);
		}
	};

	const onFinishFailed = (
		errorInfo: ValidateErrorEntity<Credentials>
	): void => {
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (isSubmit) {
			setEmailError(null);

			if (!email) {
				return setEmailError("Please enter your email");
			} else if (!pattern.test(email)) {
				return setEmailError("Please enter a valid email");
			}
		}
	};

	const handleFormChange = () => {
		setIsSubmit(false);
		setCognitorErrorMessage(null);
		setEmailError(null);
	};

	const title = (
		<Space style={{ paddingTop: "12px" }}>
			<ul>
				<li className={heightChar ? "validate" : "not-validate"}>
					8 characters minimum
				</li>
				<li className={upperChar ? "validate" : "not-validate"}>
					1 uppercase letter
				</li>
			</ul>
			<ul>
				<li className={lowerChar ? "validate" : "not-validate"}>
					1 lowercase letter
				</li>
				<li className={numberChar ? "validate" : "not-validate"}>
					1 number
				</li>
			</ul>
		</Space>
	);

	return (
		<Form
			name="basic"
			layout="vertical"
			initialValues={{ remember: true }}
			onFinish={onFinish}
			onChange={handleFormChange}
			onFinishFailed={onFinishFailed}
			autoComplete="off">
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={12}>
					<Form.Item
						name="firstName"
						rules={[
							() => ({
								validator(_, value) {
									if (!isSubmit) return Promise.resolve();

									if (!value)
										return Promise.reject(
											new Error("First name is required")
										);
									return Promise.resolve();
								},
							}),
						]}>
						<Input
							placeholder="First name"
							className="inputs"
							id="firstname-input"
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="lastName"
						rules={[
							() => ({
								validator(_, value) {
									if (!isSubmit) return Promise.resolve();
									else if (!value)
										return Promise.reject(
											new Error("Last name is required")
										);
									return Promise.resolve();
								},
							}),
						]}>
						<Input
							placeholder="Last name"
							className="inputs"
							id="lastname-input"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item name="email">
				<Input
					onChange={(e) => validateEmail(e)}
					prefix={<UserOutlined className="site-form-item-icon" />}
					placeholder="Email"
					className="inputs"
				/>
				{cognitorErrorMessage ? (
					<p style={{ color: "#ff4d4f" }}>{cognitorErrorMessage}</p>
				) : null}
				{emailError ? (
					<p style={{ color: "#ff4d4f" }}>{emailError}</p>
				) : null}
			</Form.Item>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={12}>
					<Tooltip
						trigger={["focus"]}
						title={title}
						color={"white"}
						overlayStyle={{ width: "430px" }}
						overlayInnerStyle={{
							color: "black",
							width: "430px",
							filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))",
						}}
						placement="topLeft"
						overlayClassName="numeric-input">
						<Form.Item
							name="password"
							rules={[
								() => ({
									validator(_, value) {
										const pattern = new RegExp(
											"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
										);
										if (!isSubmit) {
											return Promise.resolve();
										}
										if (!value)
											return Promise.reject(
												"Password is required"
											);
										if (pattern.test(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"Your password does not respect our rules"
											)
										);
									},
								}),
							]}>
							<Input.Password
								onChange={(e) => {
									validatePassword(e);
								}}
								type="password"
								prefix={
									<LockOutlined className="site-form-item-icon" />
								}
								placeholder="Password"
								className="inputs"
							/>
						</Form.Item>
					</Tooltip>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="confirmPassword"
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!isSubmit) {
										return Promise.resolve();
									} else if (
										getFieldValue("password") === value
									) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error(
											!value
												? "Confirm password is required"
												: "The two passwords that you entered do not match!"
										)
									);
								},
							}),
						]}>
						<Input.Password
							id="confirm-password-input"
							type="password"
							prefix={
								<LockOutlined className="site-form-item-icon" />
							}
							placeholder="Confirm"
							className="inputs"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Space
				direction="vertical"
				size="small"
				style={{ display: "flex", gap: "0px" }}>
				<Space>
					<Switch onChange={() => setTerms(!terms)} size="small" />I
					agree to all the
					<Link
						to="/gcu"
						target="_blank"
						style={{ color: "#6B4EB6" }}>
						Terms
					</Link>
					and
					<a
						target="_blank"
						href="https://www.lettria.com/privacy-policy"
						style={{ color: "#6B4EB6" }}>
						Privacy
					</a>
					policy
				</Space>
			</Space>

			<Space
				style={{
					marginTop: "24px",
					marginBottom: "16px",
					width: "100%",
				}}
				direction="vertical">
				<Button
					type="primary"
					disabled={!terms}
					htmlType="submit"
					onClick={() => {
						setIsSubmit(true);
						setEmailError(null);
					}}
					className="signup-form-button">
					Sign Up
				</Button>
				<p>
					Already have an account ?{" "}
					<Link
						to="/login"
						style={{ color: "#6B4EB6", fontWeight: "bold" }}>
						Login
					</Link>
				</p>
			</Space>
		</Form>
	);
};
