/*eslint no-unreachable: "off"*/
import { SubscriptionReducer } from "modules/types/subscription";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./subscription-methods";
import { SubscriptionActionType } from "modules/types/subscription/store";

const initialState: SubscriptionReducer = {
	map: {},
	init: false,
};

export const subscriptionReducer = (
	state = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case "RESET_SUBSCRIPTION": {
			return methods.resetSubscription(
				state,
				action as SubscriptionActionType
			);
			break;
		}
		case "SET_SUBSCRIPTION": {
			return methods.setSubscription(
				state,
				action as SubscriptionActionType
			);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
