/*eslint no-unreachable: "off"*/
import { AnalyticReducer } from "modules/types/analytic";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./analytic-methods";
import {
	AnalyticsActionType,
	AnalyticActionType,
} from "modules/types/analytic/store";

const initialState: AnalyticReducer = {
	init: false,
	map: {},
	list: [],
	analyticCards: [],
};

export const analyticReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "RESET_ANALYTICS": {
			return methods.resetAnalytics(state, action as AnalyticsActionType);
			break;
		}
		case "SET_ANALYTICS": {
			return methods.setAnalytics(state, action as AnalyticsActionType);
			break;
		}
		case "ADD_ANALYTIC": {
			return methods.addAnalytic(state, action as AnalyticActionType);
			break;
		}
		case "UPDATE_ANALYTIC": {
			return methods.updateAnalytic(state, action as AnalyticActionType);
			break;
		}
		case "DELETE_ANALYTIC": {
			return methods.deleteAnalytic(state, action as AnalyticActionType);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
