import { PayloadAction } from "@reduxjs/toolkit";
import { FlowReducer } from "modules/types/flow";

export const resetFlow = (
	state: FlowReducer
	// action: PayloadAction<Campaign[]>
) => {
	let initialState: FlowReducer = {
		stepIndex: 0,
		name: "",
		selectedDatasetsIds: [],
		url: "",
	};

	return {
		...state,
		...initialState,
	};
};

export const setFlow = (
	state: FlowReducer,
	action: PayloadAction<FlowReducer>
) => {
	let flow = action.payload;

	return {
		...state,
		...flow,
	};
};
