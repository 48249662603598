import { RequestService } from "services/request.service";
import { DictionariesResponse } from "modules/types/dictionary";

const requestService: RequestService = new RequestService();

export class RetrieveDictionaryApi extends RequestService {
	public retrieveDictionariesApi = async () => {
		const result: DictionariesResponse = await requestService.request(
			"dictionary/retrieve",
			"GET",
			{}
		);
		return result;
	};
}
