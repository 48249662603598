/*eslint no-unused-vars:0*/
import { store } from "store/store";
import { Subscription } from "modules/types/subscription";

export const resetSubscriptions = (subscriptions: Subscription[]) => {
	return store.dispatch({
		type: "RESET_SUBSCRIPTION",
		payload: subscriptions,
	});
};

export const setSubscriptions = (subscriptions: Subscription[]) => {
	return store.dispatch({
		type: "SET_SUBSCRIPTION",
		payload: subscriptions,
	});
};
