import { Project } from "modules/types/project";
import {
	ProviderType,
	RegionType,
	SubSetType,
	Training,
	TrainingInstanceType,
	TrainParametersType,
} from "modules/types/training";
import { providers as data } from "modules/data/providers";

/**
 * Generate name for the training
 * @param trainings
 * @returns
 */
export const getNameFromTrainings = (
	project: Project,
	trainings: Training[]
) => {
	const names = trainings.map((train) => train.name);
	let i = 1;
	names.forEach((name) => {
		if (name.includes(" - Training ")) {
			const nameSplitted = name.split(" ");
			const number = parseInt(nameSplitted[nameSplitted.length - 1]);
			if (number > i) i = number;
		}
	});
	return `${project.name} - Training ${i}`;
};

/**
 * Get color from training type
 * @param subSetType
 * @returns
 */
export const getColorFromSubSetType = (subSetType: SubSetType) => {
	switch (subSetType) {
		case "training":
			return "#028CFF";
		case "test":
			return "#1CDBD8";
		case "validation":
			return "#FE805B";
	}
};

/**
 * Find label from train parameters type
 * @param TrainParametersType
 * @param type
 */
export const getLabelFromTrainParametersType = (
	TrainParametersType: TrainParametersType,
	type: "provider" | "region" | "instance"
) => {
	switch (type) {
		case "provider":
			return getLabelFromProviderType(TrainParametersType.provider);
		case "region":
			return getLabelFromRegionType(
				TrainParametersType.provider,
				TrainParametersType.region
			);
		case "instance":
			return getLabelFromInstanceType(
				TrainParametersType.provider,
				TrainParametersType.region,
				TrainParametersType.instanceType
			);
	}
};

/**
 * Find label from provider type
 */
export const getLabelFromProviderType = (
	providerType: ProviderType | undefined
) => {
	const provider = Object.values(data).find((p) => p.id === providerType);
	return provider ? provider.label : "undefined";
};

/**
 * Find label from region type
 */
export const getLabelFromRegionType = (
	providerType: ProviderType | undefined,
	regionType: RegionType | undefined
) => {
	const provider = Object.values(data).find((p) => p.id === providerType);
	if (!provider) return "undefined";
	const regionData = Object.values(provider.regions).find(
		(r) => r.id === regionType
	);
	return regionData ? regionData.label : "undefined";
};

/**
 * Find label from instance type
 *
 */
export const getLabelFromInstanceType = (
	providerType: ProviderType | undefined,
	regionType: RegionType | undefined,
	instanceType: TrainingInstanceType | undefined
) => {
	const provider = Object.values(data).find((p) => p.id === providerType);
	if (!provider) return "undefined";
	const regionData = Object.values(provider.regions).find(
		(r) => r.id === regionType
	);
	if (!regionData) return "undefined";
	const instanceData = Object.values(regionData.instanceTypes).find(
		(i) => i.id === instanceType
	);
	return instanceData ? instanceData.label : "undefined";
};

/**
 * Find label from instance type in proiders.json & return provider, region and instance
 */
export const getProviderRegionInstanceFromInstanceType = (
	instanceType: TrainingInstanceType | undefined
) => {
	const provider = Object.values(data).find((p) =>
		Object.values(p.regions).find((r) =>
			Object.values(r.instanceTypes).find((i) => i.id === instanceType)
		)
	);
	if (!provider)
		return { provider: undefined, region: undefined, instance: undefined };
	const regionData = Object.values(provider.regions).find((r) =>
		Object.values(r.instanceTypes).find((i) => i.id === instanceType)
	);
	if (!regionData)
		return { provider: undefined, region: undefined, instance: undefined };
	const instanceData = Object.values(regionData.instanceTypes).find(
		(i) => i.id === instanceType
	);
	return {
		provider: provider.label,
		region: regionData.label,
		instance: instanceData ? instanceData.label : undefined,
	};
};
