import { RequestService } from "services/request.service";
import { SubscriptionResponse } from "modules/types/subscription";

const requestService: RequestService = new RequestService();

export class RetrieveSubscriptionApi extends RequestService {
	public retrieveSubscriptionsApi =
		async (): Promise<SubscriptionResponse> => {
			const result: SubscriptionResponse = await requestService.request(
				"subscription/retrieve",
				"GET",
				{}
			);
			return result;
		};
}
