/*eslint no-unreachable: "off"*/
import { AnyAction } from "@reduxjs/toolkit";
import { BooleanActionType } from "modules/types/global/store";
import { NotificationReducer } from "modules/types/notification";
import {
	NotificationsActionType,
	NotificationActionType,
	StatusActionType,
} from "modules/types/notification/store";
import * as methods from "./notification-methods";

const initialState: NotificationReducer = {
	init: false,
	allNotificationsRead: false,
	map: {},
};

export const notificationReducer = (
	state = initialState,
	action: AnyAction
): NotificationReducer => {
	switch (action.type) {
		case "RESET_NOTIFICATIONS": {
			return methods.resetNotifications(
				state,
				action as NotificationsActionType
			);
			break;
		}
		case "SET_NOTIFICATIONS": {
			return methods.setNotifications(
				state,
				action as NotificationsActionType
			);
			break;
		}
		case "UPDATE_NOTIFICATION": {
			return methods.updateNotification(
				state,
				action as NotificationActionType
			);
			break;
		}
		case "UPDATE_NOTIFICATION_STATUS": {
			return methods.updateNotificationStatus(
				state,
				action as StatusActionType
			);
			break;
		}
		case "ADD_NOTIFICATION": {
			return methods.addNotification(
				state,
				action as NotificationActionType
			);
			break;
		}
		case "NOTIFICATIONS_READ": {
			return methods.notificationsRead(state, action as any);
			break;
		}
		case "SET_ALL_NOTIFICATIONS_READ": {
			return methods.setAllNotificationsRead(
				state,
				action as BooleanActionType
			);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
