import { RessourceType } from "modules/types/global";
import { RessourceRealType } from "modules/types/global";
import {
	UserReducer,
	UserData,
	UserItems,
	OwnerType,
} from "modules/types/user";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";

export const setUser = (
	state: UserReducer,
	action: PayloadAction<UserData>
): UserReducer => {
	let data = formatKnowledgeBeforeRedux(action.payload) as UserData;
	return {
		...state,
		data,
		loggedIn: true,
		init: true,
	};
};

export const deleteUser = (
	state: UserReducer,
	action: PayloadAction<UserData>
): UserReducer => {
	return {
		...state,
		data: null,
		loggedIn: false,
	};
};

export const setLoggedIn = (
	state: UserReducer,
	action: PayloadAction<boolean>
): UserReducer => {
	return {
		...state,
		loggedIn: action.payload,
		init: true,
	};
};

export const addRessourceToUser = (
	state: UserReducer,
	action: PayloadAction<{
		userKey: RessourceType;
		ressourceId: string;
		ownerType: OwnerType;
		owner: string;
	}>
) => {
	const { userKey, ressourceId, ownerType, owner } = action.payload;
	let data = { ...state.data } as UserData;

	const userItems: UserItems = {
		id: ressourceId,
		userType: "Admin",
		ownerType: ownerType,
		owner: owner,
	};

	switch (userKey) {
		case "project":
			data.projectIds?.push(userItems);
			break;
		case "dictionary":
			data.dictionaryIds?.push(userItems);
			break;
		case "annotation-campaign":
			data.annotationCampaignIds?.push(userItems);
			break;
		case "ontology":
			data.ontologyIds?.push(userItems);
			break;
		case "pattern":
			data.patternIds?.push(userItems);
			break;
		case "dataset":
			data.dataSetIds?.push(userItems);
			break;
		case "group":
			data.groupIds?.push(userItems);
			break;
	}

	return {
		...state,
		data,
	};
};

export const deleteRessourceFromUser = (
	state: UserReducer,
	action: PayloadAction<{
		userKey: RessourceType;
		ressource: RessourceRealType;
	}>
) => {
	const { userKey, ressource } = action.payload;
	let data = { ...state.data } as UserData;

	switch (userKey) {
		case "project":
			data.projectIds = data.projectIds.filter(
				(item: UserItems) => item.id !== ressource._id
			);
			break;
		case "dictionary":
			data.dictionaryIds = data.dictionaryIds.filter(
				(item: UserItems) => item.id !== ressource._id
			);
			break;
		case "annotation-campaign":
			data.annotationCampaignIds = data.annotationCampaignIds.filter(
				(item: UserItems) => item.id !== ressource._id
			);
			break;
		case "ontology":
			data.ontologyIds = data.ontologyIds.filter(
				(item: UserItems) => item.id !== ressource._id
			);
			break;
		case "pattern":
			data.patternIds = data.patternIds.filter(
				(item: UserItems) => item.id !== ressource._id
			);
			break;
	}

	return {
		...state,
		data,
	};
};
