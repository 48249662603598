import { Group } from "modules/types/group";
import { GroupViewStore } from "./store/group-view.store.service";

export class GroupViewService extends GroupViewStore {
	/**
	 * Retrieve group members
	 * @param groupId
	 */
	public retrieveMembers = async (groupId: string) => {
		try {
			await this.retrieveMembersStore(groupId);
		} catch (e) {
			console.error("retrieveMembers", e);
		}
	};

	/**
	 * Retrieve group members
	 * @param groupId
	 */
	public retrieveAllGroupsMembers = async () => {
		try {
			await this.retrieveAllGroupsMembersStore();
		} catch (e) {
			console.error("retrieveMembers", e);
		}
	};

	/**
	 * Retrieve group ressources from ressources ids
	 * @param ids
	 */
	public retrieveRessources = async (
		group: Group,
		ids: {
			_campaignIds: string[];
			_dictionaryIds: string[];
			_projectIds: string[];
			_ontologyIds: string[];
			_patternIds: string[];
			_dataSetIds: string[];
		}
	) => {
		const { idsNotInGroup } = this.checkIfIdsInGroup(group, ids);

		if (idsNotInGroup.campaignIds.length)
			await this.retrieveCampaignsStore(idsNotInGroup.campaignIds);
		if (idsNotInGroup.dictionaryIds.length)
			await this.retrieveDictionariesStore(idsNotInGroup.dictionaryIds);
		if (idsNotInGroup.projectIds.length)
			await this.retrieveProjectsStore(idsNotInGroup.projectIds);
		if (idsNotInGroup.ontologyIds.length)
			await this.retrieveOntologiesStore(idsNotInGroup.ontologyIds);
		if (idsNotInGroup.patternIds.length)
			await this.retrievePatternsStore(idsNotInGroup.patternIds);
		if (idsNotInGroup.dataSetIds.length)
			await this.retrieveDataSetsStore(idsNotInGroup.dataSetIds);
	};

	/**
	 * Check if group ressources ids ar in Redux store
	 * @param group
	 * @param ids
	 * @returns Ids not in group
	 */
	private checkIfIdsInGroup = (
		group: Group,
		ids: {
			_campaignIds: string[];
			_dictionaryIds: string[];
			_projectIds: string[];
			_ontologyIds: string[];
			_patternIds: string[];
			_dataSetIds: string[];
		}
	) => {
		const {
			_campaignIds,
			_dictionaryIds,
			_projectIds,
			_ontologyIds,
			_patternIds,
			_dataSetIds,
		} = ids;
		const {
			annotationCampaignIds,
			dictionaryIds,
			projectIds,
			ontologyIds,
			patternIds,
			dataSetIds,
		} = group;

		const idsNotInGroup = {
			campaignIds: annotationCampaignIds.filter(
				(id) => _campaignIds.indexOf(id) === -1
			),
			dictionaryIds: dictionaryIds.filter(
				(id) => _dictionaryIds.indexOf(id) === -1
			),
			projectIds: projectIds.filter(
				(id) => _projectIds.indexOf(id) === -1
			),
			ontologyIds: ontologyIds.filter(
				(id) => _ontologyIds.indexOf(id) === -1
			),
			patternIds: patternIds.filter(
				(id) => _patternIds.indexOf(id) === -1
			),
			dataSetIds: dataSetIds.filter(
				(id) => _dataSetIds.indexOf(id) === -1
			),
		};
		return { idsNotInGroup };
	};
}
