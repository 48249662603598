import { PayloadAction } from "@reduxjs/toolkit";
import {
	Notification,
	NotificationMap,
	NotificationReducer,
	NotificationStatus,
} from "modules/types/notification";

export const resetNotifications = (
	state: NotificationReducer,
	action: PayloadAction<Notification[]>
): NotificationReducer => {
	let map: NotificationMap = {};
	action.payload.forEach((notification: Notification) => {
		map[notification?._id] = notification;
	});
	return {
		...state,
		map,
		init: true,
	};
};

export const setNotifications = (
	state: NotificationReducer,
	action: PayloadAction<Notification[]>
): NotificationReducer => {
	let map: NotificationMap = { ...state.map };
	action.payload.forEach((notification: Notification) => {
		map[notification?._id] = { ...map[notification?._id], ...notification };
	});
	return {
		...state,
		map,
		init: true,
	};
};

export const updateNotification = (
	state: NotificationReducer,
	action: PayloadAction<Notification>
) => {
	let map: NotificationMap = { ...state.map };
	map[action.payload?._id] = action.payload;
	return {
		...state,
		map,
	};
};

export const addNotification = (
	state: NotificationReducer,
	action: PayloadAction<Notification>
) => {
	let map: NotificationMap = { ...state.map };
	map[action.payload._id] = action.payload;
	return {
		...state,
		map,
	};
};

export const notificationsRead = (
	state: NotificationReducer,
	action: PayloadAction<{ userId: string }>
) => {
	let map: NotificationMap = { ...state.map };
	let { userId } = action.payload;
	Object.values(map).forEach((notification: Notification) => {
		if (notification.seenByUserIds?.indexOf(userId) == -1)
			notification.seenByUserIds?.push(userId);
	});

	return {
		...state,
		map,
	};
};

export const updateNotificationStatus = (
	state: NotificationReducer,
	action: PayloadAction<{
		notificationId: string;
		status: NotificationStatus;
	}>
) => {
	let map: NotificationMap = { ...state.map };
	map[action.payload.notificationId].status = action.payload.status;
	return {
		...state,
		map,
	};
};

export const setAllNotificationsRead = (
	state: NotificationReducer,
	action: PayloadAction<boolean>
): NotificationReducer => {
	return {
		...state,
		allNotificationsRead: action.payload,
	};
};
