/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "store/store";
import { Ontology } from "modules/types/ontology";
import { OwnerType } from "modules/types/user";

export const resetOntologies = (ontologies: Ontology[]): AnyAction => {
	return store.dispatch({
		type: "RESET_ONTOLOGIES",
		payload: ontologies,
	});
};

export const setOntologies = (ontologies: Ontology[]): AnyAction => {
	return store.dispatch({
		type: "SET_ONTOLOGIES",
		payload: ontologies,
	});
};

export const setProjectOntologies = (ontologies: Ontology[]): AnyAction => {
	return store.dispatch({
		type: "SET_PROJECT_ONTOLOGIES",
		payload: ontologies,
	});
};

export const addOntology = (ontology: Ontology): AnyAction => {
	return store.dispatch({
		type: "ADD_ONTOLOGY",
		payload: ontology,
	});
};

export const updateOntology = (ontology: Ontology): AnyAction => {
	return store.dispatch({
		type: "UPDATE_ONTOLOGY",
		payload: ontology,
	});
};

export const updateOntologyOwnerType = (
	ressourceId: string,
	ownerType: OwnerType,
	groupId: string
) => {
	return store.dispatch({
		type: "UPDATE_ONTOLOGY_OWNER",
		payload: { ressourceId, ownerType, groupId },
	});
};

export const updateOntologyDiff = (
	ontology: Ontology,
	diff: any
): AnyAction => {
	return store.dispatch({
		type: "UPDATE_ONTOLOGY_DIFF",
		payload: { ontology, diff },
	});
};

export const deleteOntology = (ontologyId: string): AnyAction => {
	return store.dispatch({
		type: "DELETE_ONTOLOGY",
		payload: { ontologyId },
	});
};
