import {
	SubscriptionMap,
	SubscriptionReducer,
} from "modules/types/subscription";
import { PayloadAction } from "@reduxjs/toolkit";
import { Subscription } from "modules/types/subscription";

export const resetSubscription = (
	state: SubscriptionReducer,
	action: PayloadAction<Subscription[]>
) => {
	let map: SubscriptionMap = {};
	for (let subscription of action.payload)
		map[subscription?._id] = subscription;

	return {
		...state,
		map,
		init: true,
	};
};

export const setSubscription = (
	state: SubscriptionReducer,
	action: PayloadAction<Subscription[]>
) => {
	let map: SubscriptionMap = { ...state.map };
	for (let subscription of action.payload)
		map[subscription?._id] = { ...map[subscription?._id], ...subscription };

	return {
		...state,
		map,
		init: true,
	};
};
