import axios from "axios";
import {
	CognitoTokenResponse,
	CognitoUserSession,
	UserData,
} from "modules/types/user";
import { RequestService } from "services/request.service";
import * as cognito from "../../libs/cognito";

export class AuthApi extends RequestService {
	public signInApi = async (
		email: string,
		password: string
	): Promise<CognitoUserSession> => {
		let userSession: CognitoUserSession = (await cognito.signInWithEmail(
			email,
			password
		)) as CognitoUserSession;
		return userSession;
	};

	public signUpApi = async (
		name: string,
		family_name: string,
		email: string,
		password: string
	): Promise<any> => {
		let userSession: CognitoUserSession =
			(await cognito.signUpUserWithEmail(
				name,
				family_name,
				email,
				email,
				password
			)) as any;
		return userSession;
	};

	public signInCognitoApi = async (code: string): Promise<any> => {
		let grant_type = "authorization_code",
			client_id = process.env.REACT_APP_CLIENT_ID!,
			redirect_uri = process.env.REACT_APP_HOST + "/auth/callback";
		let queryParams = new URLSearchParams();
		queryParams.append("redirect_uri", redirect_uri);

		const config = {
			url:
				process.env.REACT_APP_COGNITO_DOMAIN +
				"/oauth2/token" +
				`?code=${code}` +
				`&grant_type=${grant_type}` +
				`&client_id=${client_id}&` +
				queryParams.toString(),
			method: "POST",
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		try {
			const response = await axios(config);
			const data: CognitoTokenResponse = response.data;
			return data;
		} catch (error) {
			console.error("signInCognitoApi", error);
		}
	};

	public googleSignInApi = async () => {
		let response_type = "CODE";
		let client_id = process.env.REACT_APP_CLIENT_ID!;
		let redirect_uri = process.env.REACT_APP_HOST + "/auth/callback";
		let identity_provider = "Google";
		let scope = "aws.cognito.signin.user.admin email openid phone profile";

		let queryParams = new URLSearchParams();
		queryParams.append("scope", scope);
		const config = {
			url:
				process.env.REACT_APP_COGNITO_DOMAIN! +
				"/oauth2/authorize?" +
				`response_type=${response_type}&` +
				`client_id=${client_id}&` +
				`redirect_uri=${redirect_uri}&` +
				`identity_provider=${identity_provider}&` +
				queryParams.toString(),
			method: "POST",
			data: {},
			headers: {
				// "Content-Type": "application/x-www-form-urlencoded",
				// "Access-Control-Allow-Origin": "*",
			},
		};
		window.location.href = config.url;
	};

	private trackUserLogin = async (user: UserData) => {
		try {
			let createdAt = Math.ceil(
				new Date(user.createdAt)?.getTime() / 1000
			);

			window?._cio &&
				window._cio.identify({
					id: user.email, // Use either id or email.
					created_at: createdAt, // Timestamp in your system that represents when

					first_name: user.profile?.firstName, // Add any attributes you'd like to use in the email subject or body.
					last_name: user.profile?.lastName,
				});

			// if (window?.jimo) {
			// 	window.jimo.push(["set", "user:email", [user.email]]);
			// 	window.jimo.push([
			// 		"set",
			// 		"user:name",
			// 		[`${user.profile?.firstName} ${user.profile?.lastName}`],
			// 	]);
			// }
		} catch (error) {
			console.error(error);
		}
	};

	public getSelfApi = async (idToken: string): Promise<UserData> => {
		let res: UserData = await this.requestWithIdToken(
			"auth/get-self",
			"GET",
			null,
			idToken
		);
		this.trackUserLogin(res);
		return res;
	};

	public restoreSessionApi = async (): Promise<any> => {
		try {
			let res = await cognito.refreshUserToken(
				localStorage.getItem("LettriaRefreshToken")!
			);
			return res;

			// let res: any = await axios({
			// 	url: "aws/refresh-token",
			// 	method: "GET",
			// 	headers: {
			// 		Authorization: `LettriaRefreshToken ${localStorage.getItem(
			// 			"LettriaRefreshToken"
			// 		)}`,
			// 	},
			// });

			// return res?.data;
		} catch (error) {
			console.error(error);
		}
		return null!;
	};
}
