import { Row, Col, message, Typography } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SignupForm } from "./components/signup-form.component";
import "./styles/signup.style.less";

export interface Credentials {
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	password: string | null;
	confirmPassword: string | null;
}

export const Signup = () => {
	const { state }: any = useLocation();
	const navigate = useNavigate();

	const [messageApi, contextHolder] = message.useMessage();

	useEffect(() => {
		if (state?.error) {
			messageApi.open({
				type: "error",
				style: {
					marginBottom: "90vh",
					marginRight: "35vw",
				},
				content: state?.error,
			});
		}
	});

	return (
		<Row justify="center" className="signup-container">
			{contextHolder}
			<Col span={12} className="left-column" style={{ width: "58%" }}>
				<img
					className="lettria-logo"
					alt=""
					src="/images/lettria.svg"
					onClick={() => navigate("/")}
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/unlock-text.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/purposes.svg"
					style={{ marginLeft: "50%" }}
				/>
				<img
					alt=""
					src="/images/icons/clients.svg"
					style={{ bottom: "48px", position: "absolute" }}
				/>
			</Col>
			<Col span={12} className="right-column">
				<Typography.Title
					level={1}
					style={{
						margin: 0,
						wordBreak: "break-word",
					}}>
					Create an account
				</Typography.Title>
				<p className="no-card">No credit card required</p>
				<SignupForm />
			</Col>
		</Row>
	);
};
