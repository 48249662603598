import { useState, useRef } from "react";
import { Form, Input, Button, Space } from "antd";
import "../styles/verify.style.less";
import { AuthService } from "../../auth/services/auth.service";
import { useNavigate, useParams } from "react-router";
import { resendConfirmationCode } from "../../auth/libs/cognito";
import Countdown from "antd/lib/statistic/Countdown";

export const VerifyForm = (props: any) => {
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [cognitorErrorMessage, setCognitorErrorMessage] = useState<
		string | null
	>(null);
	const authService: AuthService = new AuthService();
	const [emailError, setEmailError] = useState<string | null>(null);

	const { email } = useParams();
	const password = localStorage.getItem("password");
	const [resend, setResend] = useState(false);

	const navigate = useNavigate();

	const inputsRef = useRef<Array<any>>([]);
	const setInputRef = (index: number, inputRef: any) => {
		inputsRef.current[index] = inputRef;
	};

	const onFocus = (index: number) => {
		if (inputsRef.current[index] !== undefined) {
			inputsRef.current[index].focus();
		}
	};

	const onFinish = async (values: any): Promise<void> => {
		let code = "";
		for (let i = 1; i < 7; i++) {
			code += values[`digit-${i}`];
		}
		if (!isSubmit) {
			setIsSubmit(true);
		}
		try {
			await authService.verifyCode(email!, code);
			await authService.signIn(email!, password!, true);
			navigate("/dashboard");
			localStorage.removeItem("password");
		} catch (err: any) {
			setCognitorErrorMessage(err.message);
		}
	};

	const handleResendCode = async (email: string) => {
		props.resetValueCountdown();
		resendConfirmationCode(email);
	};

	const onResend = () => {
		setResend(true);
	};

	const handleInput = (index: number, e: any) => {
		const value = e.target.value;
		if (value.length > 0 && index < 5) {
			onFocus(index + 1);
		}
	};

	return (
		<Form onFinish={onFinish} onChange={() => setIsSubmit(false)}>
			<Space size={"middle"}>
				{[1, 2, 3, 4, 5, 6].map((i) => (
					<Form.Item
						name={`digit-${i}`}
						key={`digit-${i}`}
						rules={[
							() => ({
								validator(_, value) {
									const pattern = new RegExp(/^[0-9]{6}$/i);
									if (!isSubmit) {
										return Promise.resolve();
									}
									if (pattern.test(value)) {
										return Promise.resolve();
									} else {
										return Promise.reject(
											"Please enter a valid code (6 digits) or request a code again "
										);
									}
								},
							}),
						]}>
						<Input
							required
							ref={(inputRef) => setInputRef(i - 1, inputRef)}
							maxLength={1}
							className="inputs-code"
							type={`code-${i}`}
							placeholder="0"
							style={{
								textAlign: "center",
								padding: "8px, 12px, 8px, 12px",
								borderRadius: "8px",
								border: "1px solid #D9D9D9",
								width: "65px",
								height: "40px",
							}}
							onInput={(e) => handleInput(i - 1, e)}
						/>
					</Form.Item>
				))}
			</Space>
			<div style={{ color: "red", margin: "8px" }}>
				{cognitorErrorMessage}
			</div>
			<Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					className="verify-form-button">
					Verify
				</Button>
			</Form.Item>
			I haven’t received my code yet.{" "}
			{resend ? (
				<>
					<a
						style={{
							color: "#6B4EB6",
							fontWeight: "bold",
						}}
						onClick={() => handleResendCode(email!)}>
						Resend code
					</a>
				</>
			) : (
				<>
					Resend in{" "}
					<Countdown
						style={{ display: "inline-block" }}
						value={Date.now() + 5 * 1000}
						onFinish={onResend}
					/>
				</>
			)}
		</Form>
	);
};
