import {
	EndpointDescription,
	EndpointParams,
	EndpointResponse,
	TrainingOutput,
} from "modules/types/training";

export const formatEndpoint = (endpoint: any): TrainingOutput | undefined => ({
	...endpoint,
	description: formatEndpointDescription(endpoint?.description),
	params: formatEndpointParams(endpoint?.params),
	response: formatEndpointResponse(endpoint?.response),
});

export const formatEndpointDescription = (
	description: any
): EndpointDescription | "" =>
	description && typeof description === "string"
		? {
				...(JSON.parse(description) as EndpointDescription),
		  }
		: "";

export const formatEndpointParams = (params: any): EndpointParams | "" =>
	params && typeof params === "string"
		? {
				...(JSON.parse(params) as EndpointParams),
		  }
		: "";

export const formatEndpointResponse = (response: any): EndpointResponse | "" =>
	response && typeof response === "string"
		? {
				...(JSON.parse(response) as EndpointResponse),
		  }
		: "";
