import { DictionariesResponse } from "modules/types/dictionary";
import { setDictionaries } from "store/dictionary/dictionary-actions";
import { RetrieveDictionaryApi } from "../api/retrieve-dictionary.api.service";

export class RetrieveDictionaryStore extends RetrieveDictionaryApi {
	/**
	 * Retrieve all dictionary owned by user
	 * @returns null
	 */
	public retrieveDictionaries = async () => {
		const result: DictionariesResponse =
			await this.retrieveDictionariesApi();
		setDictionaries(result.dictionaries);
	};
}
