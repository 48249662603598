import { RequestService } from "services/request.service";
import { DatasetsResponse } from "modules/types/dataset";

const requestService: RequestService = new RequestService();

export class RetrieveDatasetApi extends RequestService {
	public retrieveDatasetsApi = async () => {
		const result: DatasetsResponse = await requestService.request(
			"data-set/retrieve-many",
			"GET",
			{}
		);
		return result;
	};
}
