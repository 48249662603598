import axios, { Method } from "axios";
import { message } from "antd";
import { CognitoUserSession } from "modules/types/user";
import * as cognito from "views/public/auth//libs/cognito";
import { AuthService } from "views/public/auth/services/auth.service";

const statusCodesMessages: { [key: string]: string[] } = {
	201: ["success", "Operation successful"],
	200: ["success", "Operation successful"],
	400: ["error", "Bad request"],
	403: ["warning", "Forbidden Operation"],
	500: ["error", "Error"],
};

const isInterestingRoute = (url: string, method: string) => {
	if (method === "GET") return false;
	if (url.indexOf("find") !== -1) return false;
	if (url.indexOf("read") !== -1) return false;
	if (url.indexOf("retrieve") !== -1) return false;
	if (url.indexOf("get") !== -1) return false;
	if (url.indexOf("refresh") !== -1) return false;
	if (url.indexOf("query") !== -1) return false;
	if (url.indexOf("exist") !== -1) return false;
	if (url.indexOf("annotation-item/create") !== -1) return false;
	if (url.indexOf("auth/refresh") !== -1) return false;
	if (url.indexOf("login") !== -1) return false;
	if (url.indexOf("generate-synonyms") !== -1) return false;
	if (url.indexOf("import-data") !== -1) return false;
	if (url.indexOf("generate-suggestions") !== -1) return false;
	if (url.indexOf("/metadata") !== -1) return false;
	return true;
};

const isInterestingMessage = (message: string) => {
	if (!message || !message?.length) return false;
	if (message?.indexOf("ok") !== -1) return false;
	if (message.length < 5) return false;
	return true;
};

const cleanMessage = (message: string) => {
	let cleanMessage = message.toLowerCase();
	cleanMessage = `${cleanMessage[0].toUpperCase()}${cleanMessage.slice(1)}`;
	return cleanMessage;
};

const urlCustomMessages: { [key: string]: string } = {
	"data-set/analyze?":
		"Analyzis started successfully. Come back later to see the results.",
};

export class RequestService {
	/**
	 * Perform request to backend globally
	 * @param url url specification (without base url)
	 * @param method post, get, put ...
	 * @param body data to fetch
	 * @returns response
	 */
	public request = async (
		url: string | undefined,
		method: Method | undefined,
		body: any,
		notify: boolean = true
	) => {
		if (url && url.indexOf("annotation-group") !== -1) return null;
		let response, error;
		try {
			const token = localStorage.getItem("LettriaIdToken");

			response = await axios({
				url: url,
				method: method,
				data: body,
				headers: {
					Authorization: token
						? ("LettriaIdToken " + token).toString()
						: "Empty",
				},
			});
			await isResponse401(url, method, body, response);
		} catch (e: any) {
			console.error(e);
			response = e?.response;
			error = e;
		}
		await isResponse401(url, method, body, response);
		if (notify) notifyUser(response, url, method as string);
		if (error) throw error;
		return response?.data;
	};
	/**
	 * Perform request to backend globally
	 * @param url url specification (without base url)
	 * @param method post, get, put ...
	 * @param body data to fetch
	 * @returns response
	 */
	public requestWithIdToken = async (
		url: string | undefined,
		method: Method | undefined,
		body: any,
		idToken: string
	) => {
		if (url && url.indexOf("annotation-group") !== -1) return null;
		let response, error;
		try {
			const token = idToken;

			response = await axios({
				url: url,
				method: method,
				data: body,
				headers: {
					Authorization: token
						? ("LettriaIdToken " + token).toString()
						: "Empty",
				},
			});
			await isResponse401(url, method, body, response);
		} catch (e: any) {
			console.error(e);
			response = e?.response;
			error = e;
		}
		await isResponse401(url, method, body, response);
		notifyUser(response, url, method as string);
		if (error) throw error;
		return response?.data;
	};
}

const notifyUser = (response: any, url: string | undefined, method: string) => {
	const isInteresting = isInterestingRoute(url!, method as string);

	if (response && response.status in statusCodesMessages && isInteresting) {
		const statusCodeData = statusCodesMessages[response.status];
		let customMessageKey = Object.keys(urlCustomMessages).find((key) =>
			url?.startsWith(key)
		);
		let customMessage =
			customMessageKey && customMessageKey in urlCustomMessages
				? urlCustomMessages[customMessageKey]
				: statusCodeData[1];
		if (
			statusCodeData[0] === "success" ||
			statusCodeData[0] === "warning" ||
			statusCodeData[0] === "error"
		) {
			message[statusCodeData[0]]({
				maxCount: 1,
				content: isInterestingMessage(response?.data?.message)
					? cleanMessage(response?.data?.message)
					: customMessage,
				className: "request-response-message",
			});
		}
	} else if (response && isInteresting) {
		message.info({
			content: `${url} : ${response?.status}`,
			className: "request-response-message",
		});
	}
};

const isResponse401 = async (
	url: string | undefined,
	method: Method | undefined,
	body: any,
	response: any
) => {
	if (response.status === 401) {
		localStorage.removeItem("LettriaIdToken");

		const refreshToken = localStorage.getItem("LettriaRefreshToken");

		if (refreshToken) {
			try {
				const tokenResponse: CognitoUserSession =
					await cognito.refreshUserToken(refreshToken);

				if (tokenResponse?.idToken) {
					localStorage.setItem(
						"LettriaIdToken",
						tokenResponse.idToken.jwtToken
					);

					if (tokenResponse?.accessToken)
						localStorage.setItem(
							"LettriaAccessToken",
							tokenResponse.accessToken.jwtToken
						);

					if (tokenResponse?.refreshToken)
						localStorage.setItem(
							"LettriaRefreshToken",
							tokenResponse.refreshToken.token
						);

					response = await axios({
						url: url,
						method: method,
						data: body,
						headers: {
							Authorization: (
								"LettriaIdToken " +
								tokenResponse.idToken.jwtToken
							).toString(),
						},
					});
				} else {
					console.error("Error: no tokenResponse");
					await new AuthService().signOut();
				}
			} catch (error) {
				console.error(error);
				await new AuthService().signOut();
			}
		} else {
			console.error("Error: no refresh token");
			await new AuthService().signOut();
		}
	}
};
