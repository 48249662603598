import {
	OntologyReducer,
	ClassProperty,
	OntologyMap,
	ClassPropertyMap,
} from "modules/types/ontology";
import { PayloadAction } from "@reduxjs/toolkit";

export const setClassProperties = (
	state: OntologyReducer,
	action: PayloadAction<ClassProperty[]>
): OntologyReducer => {
	let classPropertyMap: ClassPropertyMap = JSON.parse(
		JSON.stringify(state.classPropertyMap)
	);
	let ontologyMap: OntologyMap = JSON.parse(
		JSON.stringify(state.ontologyMap)
	);

	for (let property of action.payload) {
		classPropertyMap[property._id] = {
			...classPropertyMap[property._id],
			...property,
		};
		if (ontologyMap[property.knowledgeId]) {
			if (!ontologyMap[property.knowledgeId].propertyIds) {
				ontologyMap[property.knowledgeId].propertyIds = [];
			}

			if (
				ontologyMap[property.knowledgeId].propertyIds.indexOf(
					property._id
				) === -1
			) {
				ontologyMap[property.knowledgeId].propertyIds = [
					...ontologyMap[property.knowledgeId].propertyIds,
					property._id,
				];
			}
		}
	}

	return {
		...state,
		classPropertyMap,
		ontologyMap,
	};
};

export const addClassProperty = (
	state: OntologyReducer,
	action: PayloadAction<{ ontologyId: string; classProperty: ClassProperty }>
): OntologyReducer => {
	const { ontologyId, classProperty } = action.payload;

	let classPropertyMap = { ...state.classPropertyMap };
	let ontologyMap = { ...state.ontologyMap };

	if (!ontologyMap[ontologyId].propertyIds)
		ontologyMap[ontologyId].propertyIds = [];

	if (ontologyMap[ontologyId].propertyIds.indexOf(classProperty._id) === -1)
		ontologyMap[ontologyId].propertyIds.push(classProperty._id);

	classPropertyMap[classProperty._id] = {
		...classPropertyMap[classProperty._id],
		...classProperty,
	};

	return {
		...state,
		ontologyMap,
		classPropertyMap,
	};
};

export const updateClassProperty = (
	state: OntologyReducer,
	action: PayloadAction<ClassProperty>
): OntologyReducer => {
	let classPropertyMap = { ...state.classPropertyMap };
	classPropertyMap[action.payload._id] = {
		...classPropertyMap[action.payload?._id],
		...action.payload,
	};
	return {
		...state,
		classPropertyMap,
	};
};

export const updateClassProperties = (
	state: OntologyReducer,
	action: PayloadAction<ClassProperty[]>
): OntologyReducer => {
	const classProperties = action.payload;
	let classPropertyMap = { ...state.classPropertyMap };

	classProperties.forEach((property) => {
		classPropertyMap[property._id] = {
			...classPropertyMap[property._id],
			...property,
		};
	});

	return {
		...state,
		classPropertyMap,
	};
};

export const deleteClassProperty = (
	state: OntologyReducer,
	action: PayloadAction<{ ontologyId: string; classProperty: ClassProperty }>
): OntologyReducer => {
	const { ontologyId, classProperty } = action.payload;

	let classPropertyMap = { ...state.classPropertyMap };
	let ontologyMap = { ...state.ontologyMap };

	if (ontologyMap[ontologyId]) {
		const index = ontologyMap[ontologyId].propertyIds.indexOf(
			classProperty._id
		);
		let _ontologyPropertyIds = [...ontologyMap[ontologyId].propertyIds];
		_ontologyPropertyIds.splice(index, 1);
		ontologyMap[ontologyId].propertyIds = _ontologyPropertyIds;

		Object.values(ontologyMap[ontologyId].knowledgeCardMap).forEach(
			(ontologyClass) => {
				if (ontologyClass.propertyIds) {
					const propertyIndex = ontologyClass.propertyIds.indexOf(
						classProperty._id
					);
					if (propertyIndex !== -1) {
						let _propertyIds = [...ontologyClass.propertyIds];
						_propertyIds.splice(propertyIndex, 1);
						ontologyClass.propertyIds = _propertyIds;
					}
				}
				if (ontologyClass.valueMap[classProperty._id])
					delete ontologyClass.valueMap[classProperty._id];
			}
		);

		if (classPropertyMap && classPropertyMap[classProperty._id])
			delete classPropertyMap[classProperty._id];
		else
			console.error(
				`INTERNAL_ERROR: "classPropertyMap[classProperty._id]" is undefined`
			);
	} else {
		console.error(`INTERNAL_ERROR: "ontologyMap[ontologyId]" is undefined`);
	}

	for (let classPropertyId in classPropertyMap) {
		if (
			classPropertyMap[classPropertyId].path.indexOf(
				`${classProperty.path === "/" ? "" : classProperty.path}/${
					classProperty._id
				}`
			) !== -1
		) {
			delete classPropertyMap[classPropertyId];
		}
	}

	return {
		...state,
		ontologyMap,
		classPropertyMap,
	};
};

export const setSelectedProperties = (
	state: OntologyReducer,
	action: PayloadAction<{
		ontologyId: string;
		classPropertyIds: string[];
	}>
): OntologyReducer => {
	const { ontologyId, classPropertyIds } = action.payload;
	let ontologyMap = { ...state.ontologyMap };

	if (ontologyMap[ontologyId]) {
		ontologyMap[ontologyId].selectedPropertyIds = classPropertyIds;
	}

	return {
		...state,
		ontologyMap,
	};
};
