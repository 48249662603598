import { setOntologies } from "store/ontology/actions";
import { RetrieveOntologyApi } from "../api/retrieve-ontology.api.service";

export class RetrieveOntologyStore extends RetrieveOntologyApi {
	/**
	 * Retrieve all ontology owned by user
	 * @returns null
	 */
	public retrieveOntologies = async () => {
		const result = await this.retrieveOntologiesApi();
		if (result?.knowledges) {
			setOntologies(result?.knowledges);
		}
	};
}
