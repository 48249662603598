import { FileStoragesResponse } from "modules/types/file-storage";
import { setFileStorages } from "store/file-storage/file-storage-actions";
import { RetrieveFileStorageApi } from "../api/retrieve-file-storage.api.service";

export class RetrieveFileStorageStore extends RetrieveFileStorageApi {
	/**
	 * Retrieve all fileStorage owned by user
	 * @returns null
	 */
	public retrieveFileStorages = async () => {
		const result: FileStoragesResponse =
			await this.retrieveFileStoragesApi();
		if (Array.isArray(result)) setFileStorages(result);
	};
}
