import { Group } from "modules/types/group";
import {
	ClassProperty,
	KnowledgeCard,
	Ontology,
	OntologyMap,
	PropertyValue,
} from "modules/types/ontology";
import { Project } from "modules/types/project";
import { UserData, UserItems } from "modules/types/user";
import {
	blockDiscardedSuggestions,
	removeUsedSuggestions,
} from "./knowledge-card";

/**
 * Format Object with **knowledgeIds** attribute to **ontologyIds** attribute
 * @param value
 * @returns
 */
export const formatKnowledgeBeforeRedux = (
	value: UserData | Group[] | Project[]
) => {
	if (Array.isArray(value) && value.length) {
		if ("subscriptionId" in value[0]) {
			// Condition if value = Project
			let projects = value as Project[];
			projects.forEach((project: Project) => {
				if (project?.knowledgeIds) {
					project.ontologyIds = project.knowledgeIds as string[];
					delete project["knowledgeIds"];
				} else if (!project.ontologyIds) {
					project.ontologyIds = [];
				}
			});
			return projects as Project[];
		} else {
			// Condition if value = Group
			let groups = value as Group[];
			groups.forEach((group: Group) => {
				if (group?.knowledgeIds) {
					group.ontologyIds = group?.knowledgeIds as string[];
					delete group["knowledgeIds"];
				}
			});
			return groups as Group[];
		}
	} else if (typeof value === "object") {
		// Condition if value = Userdata
		let userData = value as UserData;
		if (userData?.knowledgeIds) {
			userData.ontologyIds = userData?.knowledgeIds as UserItems[];
			delete userData["knowledgeIds"];
		}
		return userData as UserData;
	}
};

/**
 * Format ontology to generate class map and ontology properties-list
 * ? update OntologyResponse Type and use it
 * @param ontologies
 * @returns
 */
export const formatOntologies = (
	ontologies: any[],
	ontologyMap: OntologyMap
) => {
	ontologies.forEach((ontology) => {
		if (!ontology.knowledgeCardMap) ontology.knowledgeCardMap = {};

		if (!ontology.propertyIds) {
			if (!ontologyMap[ontology._id]?.propertyIds) {
				ontology.propertyIds = [];
			} else {
				ontology.propertyIds = [
					...ontologyMap[ontology._id]?.propertyIds,
				];
			}
		}

		if (
			Array.isArray(ontology.knowledgeCardIds) &&
			((ontology.knowledgeCardIds.length &&
				typeof ontology.knowledgeCardIds[0] != "string") ||
				!ontology.knowledgeCardIds.length)
		) {
			const _knowledgeCards = [...ontology.knowledgeCardIds];
			ontology.knowledgeCardIds = [];
			_knowledgeCards.forEach((card: any) => {
				updateKnowledgeCardMap(ontology, formatValueListToMap(card));
			});
		}
	});
	return ontologies;
};

/**
 * Add or update class to knowledge-card map:
 * * its properties to ontology properties
 * * its suggestions in knowledge-card map
 * * its used suggestions in knowledge-card map
 * * its discarded suggestions in knowledge-card map
 * @param ontology
 * @param knowledgeCard
 * @returns Ontology
 */
export const updateKnowledgeCardMap = (
	ontology: Ontology,
	knowledgeCard: KnowledgeCard
) => {
	if (!ontology.knowledgeCardIds?.length && !knowledgeCard) return ontology;
	const index = ontology.knowledgeCardIds.indexOf(knowledgeCard._id);
	if (index !== -1) ontology.knowledgeCardIds.splice(index, 1);
	ontology.knowledgeCardIds.push(knowledgeCard._id);

	// MERGE old & new knowledge-card to update class map
	if (!ontology.knowledgeCardMap) ontology.knowledgeCardMap = {};
	if (!ontology.knowledgeCardMap[knowledgeCard._id])
		ontology.knowledgeCardMap[knowledgeCard._id] = knowledgeCard;

	ontology.knowledgeCardMap[knowledgeCard._id] = {
		...ontology.knowledgeCardMap[knowledgeCard._id],
		...(Array.isArray(knowledgeCard.valueMap)
			? formatValueListToMap(knowledgeCard)
			: knowledgeCard),
	};

	// BLOCK discarded suggestions
	ontology.knowledgeCardMap[knowledgeCard._id].suggestions =
		blockDiscardedSuggestions(
			ontology.knowledgeCardMap[knowledgeCard._id].suggestions,
			ontology.knowledgeCardMap[knowledgeCard._id]?.discardedSuggestions
		);

	// REMOVE used suggestions
	ontology.knowledgeCardMap[knowledgeCard._id] = removeUsedSuggestions(
		ontology.knowledgeCardMap[knowledgeCard._id]
	);

	// ADD properties to ontology properties
	if (!knowledgeCard.propertyIds) knowledgeCard.propertyIds = [];
	knowledgeCard?.propertyIds?.forEach((property) => {
		if (ontology.propertyIds.indexOf(property) === -1)
			ontology.propertyIds.push(property);
	});
	return ontology;
};

/**
 * Format knowledge card to create/fill value map
 * @param knowledgeCard
 * @returns
 */
export const formatValueListToMap = (knowledgeCard: any): KnowledgeCard => {
	let _knowledgeCard = { ...knowledgeCard } as KnowledgeCard;
	if (knowledgeCard.valueMap) {
		const values: PropertyValue[] = [...knowledgeCard.valueMap];
		_knowledgeCard.valueMap = {};
		values.forEach((value: any) => {
			_knowledgeCard.valueMap[value.propertyId] = value;
		});
	} else {
		_knowledgeCard.valueMap = {};
	}
	return _knowledgeCard;
};

/**
 * Format knowledge card to create/fill value map
 * @param knowledgeCards
 * @returns
 */
export const formatValueMapToList = (knowledgeCards: KnowledgeCard[]) => {
	return knowledgeCards.map((knowledgeCard) => {
		let _knowledgeCard: any = { ...knowledgeCard };
		if (_knowledgeCard.valueMap) {
			_knowledgeCard.valueMap = Object.values(
				knowledgeCard.valueMap
			).filter((vm) => vm.propertyId);
		} else {
			_knowledgeCard.valueMap = [];
		}
		return _knowledgeCard;
	});
};

/**
 * Delete children values when detach property in parent class
 * @param propertyId
 * @param knowledgeCard
 * @param knowledgeCards
 * @param deletePropertyId false by default
 * @returns
 */
export const deleteChildrenValuesOfClassProperty = (
	propertyId: string,
	knowledgeCard: KnowledgeCard,
	knowledgeCards: KnowledgeCard[],
	deletePropertyId = false
) => {
	const cards = knowledgeCards
		.filter(
			(_knowledgeCard) =>
				_knowledgeCard.path.indexOf(knowledgeCard._id) !== -1 &&
				_knowledgeCard.valueMap[propertyId]
		)
		.map((card) => {
			delete card.valueMap[propertyId];
			if (deletePropertyId) {
				const index = card.propertyIds.indexOf(propertyId);
				if (index !== -1) card.propertyIds.splice(index, 1);
			}
			return card;
		});
	return [...cards, knowledgeCard];
};

/**
 *
 * @param knowledgeCards
 * @param property
 * @returns
 */
export const deletePropertyLinks = (
	knowledgeCards: KnowledgeCard[],
	property: ClassProperty
) => {
	return knowledgeCards
		.filter(
			(knowledgeCard) =>
				knowledgeCard.propertyIds &&
				knowledgeCard.propertyIds.indexOf(property._id) !== -1
		)
		.map((card) => {
			const index = card.propertyIds.indexOf(property._id);
			card.propertyIds.splice(index, 1);
			if (card.valueMap[property._id]) delete card.valueMap[property._id];
			return card;
		});
};
