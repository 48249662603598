import {
	TrainingInstanceType,
	ProviderType,
	RegionType,
} from "modules/types/training";

export type ProviderListDataType = {
	[provider: string]: ProviderDataType;
};

export type ProviderDataType = {
	id: ProviderType;
	label: string;
	regions: {
		[region: string]: RegionDataType;
	};
};

export type RegionDataType = {
	id: RegionType;
	label: string;
	instanceTypes: {
		[instanceType: string]: InstanceDataType;
	};
};

export type InstanceDataType = {
	id: TrainingInstanceType;
	jobs: InstanceDataTypeJob[];
	label: string;
	vCPU: string;
	RAM: string;
	description: string;
	price: string;
};

export type ModelInstancesType = {
	[key in "train" | "deploy" | "deploy-advanced"]: {
		label: string;
		description: string;
		instance_type: string;
		instance_description?: string;
		price: number;
		metadatas: {
			label: string;
			value: string;
		}[];
	}[];
};

export type InstanceDataTypeJob = "training" | "inference";

export const modelInstances: ModelInstancesType = require("./model-instances.json");
export const providers: ProviderListDataType = require("./providers-test.json");

export const hyperParameters = require("./hyper-parameters.json");
