/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { Training } from "modules/types/training";
import { store } from "store/store";

/**
 * DICTIONAYR ACTIONS
 */

export const resetTrainings = (trainings: Training[]): AnyAction => {
	return store.dispatch({
		type: "RESET_TRAININGS",
		payload: { trainings },
	});
};

export const setTrainings = (trainings: Training[]): AnyAction => {
	return store.dispatch({
		type: "SET_TRAININGS",
		payload: { trainings },
	});
};

export const deleteTrainings = (trainingIds: string[]): AnyAction => {
	return store.dispatch({
		type: "DELETE_TRAININGS",
		payload: { trainingIds },
	});
};
