import { PatternResponse } from "modules/types/pattern";
import { setPatterns } from "store/pattern/pattern-actions";
import { RetrievePatternApi } from "../api/retrieve-pattern.api.service";

export class RetrievePatternStore extends RetrievePatternApi {
	/**
	 * Retrieve all pattern owned by user
	 * @returns null
	 */
	public retrievePatterns = async () => {
		const result: PatternResponse = await this.retrievePatternsApi();
		setPatterns(result.patterns);
	};
}
