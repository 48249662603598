import { RequestService } from "services/request.service";

const requestService: RequestService = new RequestService();

export class RetrieveSuggestionApi extends RequestService {
	public retrieveSuggestionsApi = async () => {
		const result = await requestService.request(
			"suggestion/retrieve",
			"GET",
			{}
		);
		return result;
	};
}
