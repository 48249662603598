import { setCampaigns } from "store/annotation-campaign/annotation-campaign-actions";
import { RetrieveCampaignApi } from "../api/retrieve-annotation-campaign.api.service";
import { formatCampaigns } from "modules/utils/category";
import { Campaign } from "modules/types/annotation-campaign";

export class RetrieveCampaignStore extends RetrieveCampaignApi {
	/**
	 * Retrieve all project owned by user
	 * @returns null
	 */
	public retrieveCampaigns = async () => {
		const result = await this.retrieveCampaignsApi();
		if (result?.annotate_campaigns) {
			// setCampaigns(result?.annotate_campaigns);
			setCampaigns(
				formatCampaigns(result?.annotate_campaigns) as Campaign[]
			);
		}

		// For transition from snake_case to camelCase
		if (result?.annotateCampaigns) {
			// setCampaigns(result?.annotateCampaigns);
			setCampaigns(
				formatCampaigns(result?.annotateCampaigns) as Campaign[]
			);
		}
	};
}
