import { RequestService } from "services/request.service";

export class RetrieveCampaignApi extends RequestService {
	public retrieveCampaignsApi = async () => {
		const result = await this.request(
			"annotation-campaign/retrieve",
			"GET",
			{}
		);
		return result;
	};
}
