import { PayloadAction } from "@reduxjs/toolkit";
import { AnnotationDataV2 } from "modules/types/annotation";
import {
	AnnotationDataReducer,
	ByCampaignConfigData,
} from "modules/types/annotation-data";

/**
 * TODO: Check return value in list
 * @param state
 * @param action
 * @returns
 */
export const setAnnotationDatasByCampaign = (
	state: AnnotationDataReducer,
	action: PayloadAction<{
		list: AnnotationDataV2[];
		count: number;
		config: ByCampaignConfigData;
	}>
) => {
	const { list, config, count } = action.payload;
	let byCampaign = { ...state.byCampaign };
	let map = { ...state.map };

	let annotationDataIds = list.map((data, i) => {
		map[data._id] = data;
		return data._id;
	});

	byCampaign[config.annotationCampaignId] = {
		config,
		list: annotationDataIds,
		count,
	};

	return {
		...state,
		byCampaign,
		map,
		init: true,
	};
};

export const deleteAnnotationDatasByCampaign = (
	state: AnnotationDataReducer,
	action: PayloadAction<{ annotationCampaignId: string }>
) => {
	let byCampaign = { ...state.byCampaign };
	delete byCampaign[action.payload.annotationCampaignId];

	return {
		...state,
		byCampaign,
	};
};

export const resetAnnotationDatas = (
	state: AnnotationDataReducer,
	action: PayloadAction<{ annotationDatas: AnnotationDataV2[] }>
): AnnotationDataReducer => {
	let map: Record<string, AnnotationDataV2> = {};
	for (let annotationData of action.payload.annotationDatas)
		map[annotationData?._id] = annotationData;
	return {
		...state,
		map,
		init: true,
	};
};

export const setAnnotationDatas = (
	state: AnnotationDataReducer,
	action: PayloadAction<{ annotationDatas: AnnotationDataV2[] }>
): AnnotationDataReducer => {
	let map: Record<string, AnnotationDataV2> = { ...state.map };
	for (let annotationData of action.payload.annotationDatas)
		map[annotationData?._id] = {
			...map[annotationData?._id],
			...annotationData,
		};
	return {
		...state,
		map,
		init: true,
	};
};

export const deleteAnnotationDatas = (
	state: AnnotationDataReducer,
	action: PayloadAction<{ annotationDataIds: string[] }>
) => {
	let annotationDataMap: Record<string, AnnotationDataV2> = { ...state.map };
	for (let annotationDataId of action.payload.annotationDataIds)
		delete annotationDataMap[annotationDataId];

	return {
		...state,
		map: annotationDataMap,
	};
};
