/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import RoutesManager from "./views/routes/routes-manager.component";
import axios from "axios";
import { ConfigProvider, message } from "antd";
import { intializePushHandler } from "./services/app/service";
import "./index.less";
import fileTokens from "./themes/theme-tokens";

const prod = "https://api.app.lettria.com/app/";
const preprod = "https://preprod.api.app.lettria.com/app/";
const dev = "https://dev.api.app.lettria.com/app/";
const localhost = "http://localhost:4895/app/";

// axios.defaults.baseURL = `${prod}`;

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/app`;

// Hide all findDOMNode related errors caudes by ant-d with React StrictMode
const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
	if (
		// process.env.NODE_ENV === "development" &&
		typeof errObj.message === "string" &&
		args.includes("findDOMNode")
	) {
		return;
	}
	consoleError(errObj, ...args);
};

const Index = () => {
	const [messageApi, contextHolder] = message.useMessage();

	return (
		<Provider store={store}>
			<ConfigProvider
				theme={{
					inherit: false,

					token: {
						// ...fileTokens,
						fontFamily: "Inter",
						colorPrimary: "#8650ff",
						colorTextSecondary: "rgba(0, 0, 0, 0.65)",
						colorTextTertiary: "rgba(0, 0, 0, 0.65)",
						colorTextQuaternary: "rgba(0, 0, 0, 0.45)",
						colorInfo: "#8650ff",
						colorInfoActive: "#8650ff",

						// lineHeight: 1.5714285714285716,
						// wireframe: false,
						// borderRadius: 6,
						// colorBgLayout: "#ffffff",

						// colorIcon: "red",
						// colorLink: "red",
						// colorLinkActive: "red",
						// colorLinkHover: "red",
						// colorTextDescription: "red",
					},
					// components: {
					// 	Table: { tableHeaderIconColor: "red" },
					// },
				}}
				getPopupContainer={(triggerNode: HTMLElement | undefined) =>
					(triggerNode?.parentNode as HTMLElement) || document.body
				}>
				{contextHolder}

				<RoutesManager />
			</ConfigProvider>
		</Provider>
	);
};

intializePushHandler();

serviceWorker.register();

ReactDOM.render(
	<React.StrictMode>
		<Index />
	</React.StrictMode>,
	document.getElementById("root")
);
