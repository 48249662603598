import { setUser } from "store/user/user-actions";
import { RetrieveUserApi } from "../api/retrieve-user.api.service";

export class RetrieveUserStore extends RetrieveUserApi {
	public retrieveUserStore = async () => {
		const result = await this.retrieveUserApi();
		if (result) {
			setUser(result);
			return result;
		}
		return false;
	};
}
