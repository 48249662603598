import { AnalyticMap, Analytic, AnalyticReducer } from "modules/types/analytic";
import { PayloadAction } from "@reduxjs/toolkit";

export const resetAnalytics = (
	state: AnalyticReducer,
	action: PayloadAction<Analytic[]>
): AnalyticReducer => {
	let map: AnalyticMap = {};
	for (let analytic of action.payload) map[analytic?._id] = analytic;
	return {
		...state,
		map,
		init: true,
	};
};

export const setAnalytics = (
	state: AnalyticReducer,
	action: PayloadAction<Analytic[]>
): AnalyticReducer => {
	let map: AnalyticMap = { ...state.map };
	for (let analytic of action.payload)
		map[analytic?._id] = { ...map[analytic?._id], ...analytic };
	return {
		...state,
		map,
		init: true,
	};
};

export const addAnalytic = (
	state: AnalyticReducer,
	action: PayloadAction<Analytic>
) => {
	let analyticsMap: AnalyticMap = { ...state.map };
	analyticsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: analyticsMap,
	};
};

export const updateAnalytic = (
	state: AnalyticReducer,
	action: PayloadAction<Analytic>
) => {
	let analyticsMap: AnalyticMap = { ...state.map };
	analyticsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: analyticsMap,
	};
};

export const deleteAnalytic = (
	state: AnalyticReducer,
	action: PayloadAction<Analytic>
) => {
	let analyticsMap: AnalyticMap = { ...state.map };
	delete analyticsMap[action.payload._id];

	return {
		...state,
		map: analyticsMap,
	};
};
