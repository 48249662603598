import {
	BodyElement,
	RessourceRealType,
	RessourceType,
} from "modules/types/global";
import { formatRoute } from "modules/utils/ressource";
import { RequestService } from "services/request.service";

type ReadResponse = {
	count: number;
	documents: RessourceRealType[];
};

export class RetrieveRessourceApi extends RequestService {
	/**
	 *
	 * @param ressourceType
	 * @param body
	 * @returns
	 */
	protected retrieveRessourcesApi = async (
		ressourceType: RessourceType,
		body: BodyElement
	): Promise<ReadResponse> =>
		await this.request(`${formatRoute(ressourceType)}/read`, "POST", body);
}
