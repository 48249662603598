import {
	EndpointDescription,
	TrainingInstanceType,
	TrainingMethod,
	TrainingOutput,
} from "modules/types/training";
import { formatEndpoint, formatTrainingJob } from "./format";
import { InstanceDataTypeJob, providers as data } from "modules/data/providers";
import { AirflowDagRun } from "modules/types/training/airflow";
import { Project } from "modules/types/project";

export const endpointAllStatusData: Record<
	string,
	{ color: string; label: string }
> = {
	default: {
		color: "default",
		label: "Inactive",
	},
	Updating: {
		color: "purple",
		label: "Updating",
	},
	Initializing: {
		color: "purple",
		label: "Initializing",
	},
	Failed: {
		color: "red",
		label: "Failed",
	},
	Creating: {
		color: "blue",
		label: "Creating",
	},
	InService: {
		color: "green",
		label: "Active",
	},
	Deleting: {
		color: "red",
		label: "Deleting",
	},
};

export const formatTrainingOutput = (outputs: any[]): TrainingOutput[] => [
	...outputs.map((output) => ({
		...output,
		trainingJob: formatTrainingJob(output?.trainingJob),
		endpoint: formatEndpoint(output?.endpoint),
		prodEndpoint: formatEndpoint(output?.prodEndpoint),
	})),
];

export const getMethodLabel = (method: TrainingMethod) => {
	switch (method) {
		case "hybrid":
			return "Hybrid";
		case "model":
			return "Auto-Lettria";
		case "pattern":
			return "Pattern";
	}
};

export const getProviderData = (
	instanceType: TrainingInstanceType,
	job: InstanceDataTypeJob
) => {
	for (let provider of Object.values(data)) {
		for (let region of Object.values(provider.regions)) {
			if (
				region.instanceTypes[instanceType] &&
				region.instanceTypes[instanceType].jobs.includes(job)
			) {
				return {
					provider,
					region,
					instanceType: region.instanceTypes[instanceType],
				};
			}
		}
	}
	return undefined;
};

/**
 * Return endpoint status
 * * If endpoint description --> watch endpoint description status & isDeployed
 * * If no endpoint description --> watch **dagRuns** status
 *   * If `deploymentDagRun` --> watch deployment dag run status (*running* & *queued*)
 *     * If `queued` --> status is **Updating**
 *     * If `running` -->  status is **InService**
 *   * If `deleteDagRun` --> watch delete dag run status (*running* & *queued*)
 *     * If `queued` --> status is **Deleting**
 *     * If `running` -->  status is **Inactive**
 * @param trainingOutput
 * @param deploymentDagRun
 * @param deleteDagRun
 * @returns
 */
export const getEndpointStatus = (
	project: Project,
	trainingOutput: TrainingOutput,
	deploymentDagRun?: AirflowDagRun,
	deleteDagRun?: AirflowDagRun
) => {
	console.log("getEndpointStatus", {
		project,
		trainingOutput,
		deploymentDagRun,
		deleteDagRun,
	});


	const endpointKey: "endpoint" | "prodEndpoint" =
		project.env === "prod" ? "prodEndpoint" : "endpoint";
	let endpointData: EndpointDescription | undefined;
	let endpointInformations = trainingOutput[endpointKey] as any;

	// console.log({
	// 	endpointKey,
	// 	endpointInformations,
	// });

	try {
		if (
			endpointInformations?.description &&
			typeof endpointInformations?.description === "string"
		) {
			let descriptionString = endpointInformations.description as string;
			endpointData = JSON.parse(descriptionString);
		} else {
			endpointData = endpointInformations?.description as any;
		}
	} catch (e) {
		console.error(e);
	}
	// console.log({ endpointData });

	let isDeployed = endpointInformations?.isDeployed;
	let endpointStatusData: { color: string; label: string } =
		endpointAllStatusData?.["default"];

	if (endpointData?.EndpointStatus)
		endpointStatusData =
			endpointAllStatusData?.[endpointData.EndpointStatus];

	// console.log({ endpointStatusData });

	if (endpointStatusData.label === "Inactive" && isDeployed)
		if (deploymentDagRun?.state) {
			switch (deploymentDagRun.state) {
				// case "success":
				// 	endpointStatusData = endpointAllStatusData?.["InService"];
				// 	break;
				case "running":
				case "queued":
					endpointStatusData = endpointInformations?.isDeployed
						? endpointAllStatusData?.["Updating"]
						: endpointAllStatusData?.["Initializing"];
					break;
			}
		}

	// console.log({ endpointStatusData });

	// if (!isDeployed) endpointStatusData = endpointAllStatusData?.["default"];
	if (deleteDagRun?.state) {
		switch (deleteDagRun.state) {
			case "success":
				endpointStatusData = endpointAllStatusData?.["default"];
				break;
			case "running":
			case "queued":
				endpointStatusData = endpointAllStatusData?.["Deleting"];
				break;
		}
	}

	// console.log({ endpointStatusData });

	return {
		// isEndpointDeployed: isDeployed,
		// isEndpointDeployed: endpointStatusData.label === "Active",
		endpointStatusData,
	};
};
