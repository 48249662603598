import {
	TrainingJobDescription,
	TrainingJobModelResult,
	TrainingJobParams,
	TrainingJobResponse,
	TrainingModelResultOutput,
	TrainingOutput,
} from "modules/types/training";

export const cleanMetricNameForMlflow = (label: string) => {
	if (label == "O") return "o";
	const correctChars = label.replace(/[^a-zA-Z0-9_. /-]/g, "_");
	const withoutDuplicateUnderscores = label.replace(/_+/g, "_");
	const withoutWhiteSpaces = withoutDuplicateUnderscores.replace(/\s+/g, "_");
	const withoutAscii = withoutWhiteSpaces.replace(/[^\x00-\x7F]/g, "");
	const withoutDash = withoutAscii.replace(/-/g, "_");

	return withoutDash;
};

export const formatTrainingJob = (
	trainingJob: any
): TrainingOutput | undefined => ({
	...trainingJob,
	description: formatTrainingJobDescription(trainingJob?.description),
	modelResults: formatTrainingJobModelResults(trainingJob?.modelResults),
	params: formatTrainingJobParams(trainingJob?.params),
	response: formatTrainingJobResponse(trainingJob?.response),
});

export const formatTrainingJobDescription = (
	description: any
): TrainingJobDescription | "" =>
	description && typeof description === "string"
		? {
				...(JSON.parse(description) as TrainingJobDescription),
		  }
		: "";

export const formatTrainingJobModelResults = (
	modelResults: any
): TrainingJobModelResult | "" =>
	modelResults && typeof modelResults === "string"
		? formatModelResults(
				JSON.parse(
					JSON.parse(modelResults)
				) as TrainingModelResultOutput
		  )
		: "";

export const formatTrainingJobParams = (params: any): TrainingJobParams | "" =>
	params && typeof params === "string"
		? {
				...(JSON.parse(params) as TrainingJobParams),
		  }
		: "";

export const formatTrainingJobResponse = (
	response: any
): TrainingJobResponse | "" =>
	response && typeof response === "string"
		? {
				...(JSON.parse(response) as TrainingJobResponse),
		  }
		: "";

export const formatModelResults = (
	modelResults: TrainingModelResultOutput
): TrainingJobModelResult | "" =>
	modelResults
		? {
				best_model: modelResults.best_model,
				results: Object.keys(modelResults.results).map((key) => ({
					label: key,
					precision: modelResults.results[key].precision,
					recall: modelResults.results[key].recall,
					f1_score: modelResults.results[key]["f1_score"],
					support: modelResults.results[key].support,
				})),
		  }
		: "";
