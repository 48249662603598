import { TrainingMap, Training, TrainingReducer } from "modules/types/training";
import { PayloadAction } from "@reduxjs/toolkit";

export const resetTrainings = (
	state: TrainingReducer,
	action: PayloadAction<{ trainings: Training[] }>
): TrainingReducer => {
	let trainingMap: TrainingMap = {};

	for (let training of action.payload.trainings)
		if (training._id) trainingMap[training._id] = training;

	return {
		...state,
		trainingMap,
		init: true,
	};
};

export const setTrainings = (
	state: TrainingReducer,
	action: PayloadAction<{ trainings: Training[] }>
): TrainingReducer => {
	let trainingMap: TrainingMap = { ...state.trainingMap };

	for (let training of action.payload.trainings)
		if (training._id)
			trainingMap[training._id] = {
				...trainingMap[training?._id],
				...training,
			};

	return {
		...state,
		trainingMap,
		init: true,
	};
};

export const deleteTrainings = (
	state: TrainingReducer,
	action: PayloadAction<{ trainingIds: string[] }>
) => {
	let trainingMap: TrainingMap = { ...state.trainingMap };

	for (let trainingId of action.payload.trainingIds)
		delete trainingMap[trainingId];

	return {
		...state,
		trainingMap,
	};
};
