/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthService } from "../auth/services/auth.service";

export const CognitoAuthRelay = () => {
	const authService: AuthService = new AuthService();
	const navigate = useNavigate();
	const location = useLocation();
	const [code, setCode] = useState<string | null>(null);

	useEffect(() => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const errorDescription = params.get("error_description")?.split(" ");

		(async () => {
			let code = new URLSearchParams(location.search).get("code");
			setCode(code);
			if (errorDescription) {
				let checkIfAlready = errorDescription.splice(2, -2);
				let _checkIfAlready = checkIfAlready?.join(" ");

				let attributesRequired = errorDescription.slice(0, 2);
				let _attributesRequired = attributesRequired?.join(" ");

				if (_checkIfAlready === "Already found an entry for username") {
					navigate("/login", {
						state: {
							error: "Already found an entry for username",
						},
					});
				} else if (_attributesRequired === "attributes required:") {
					navigate("/signup", {
						state: {
							error: "Your google account is missing attributes please complete the signup process",
						},
					});
				} else {
					console.error("message error", errorDescription);
				}
			}
			if (code) {
				authService.signInCognito(code);
			}
		})();
	}, [location.search]);

	return <></>;
};

export default CognitoAuthRelay;
