import { RessourceRealType, RessourceType } from "modules/types/global";
import { GroupMap, Group, GroupReducer, Member } from "modules/types/group";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";
import { UserType } from "modules/types/user";

export const resetGroups = (
	state: GroupReducer,
	action: PayloadAction<Group[]>
): GroupReducer => {
	let groups = formatKnowledgeBeforeRedux(action.payload) as Group[];
	let map: GroupMap = {};
	groups.forEach((group: Group) => {
		map[group?._id] = group;
	});

	return {
		...state,
		map,
		init: true,
	};
};

export const setGroups = (
	state: GroupReducer,
	action: PayloadAction<Group[]>
): GroupReducer => {
	let groups = formatKnowledgeBeforeRedux(action.payload) as Group[];
	let map: GroupMap = { ...state.map };
	groups.forEach((group: Group) => {
		map[group?._id] = { ...map[group?._id], ...group };
	});

	return {
		...state,
		map,
		init: true,
	};
};

export const addGroup = (state: GroupReducer, action: PayloadAction<Group>) => {
	let groups = formatKnowledgeBeforeRedux([action.payload]) as Group[];
	let groupsMap: GroupMap = { ...state.map };
	groupsMap[action.payload?._id] = groups[0];
	return {
		...state,
		map: groupsMap,
	};
};

export const updateGroup = (
	state: GroupReducer,
	action: PayloadAction<Group>
) => {
	let groups = formatKnowledgeBeforeRedux([action.payload]) as Group[];
	let map: GroupMap = { ...state.map };
	map[groups[0]?._id] = groups[0];

	return {
		...state,
		map,
	};
};

export const deleteGroup = (
	state: GroupReducer,
	action: PayloadAction<{ groupId: string }>
) => {
	let groupsMap: GroupMap = { ...state.map };
	if (groupsMap[action.payload.groupId])
		delete groupsMap[action.payload.groupId];

	return {
		...state,
		map: groupsMap,
	};
};

export const setGroupMembers = (
	state: GroupReducer,
	action: PayloadAction<{ groupId: string; members: Member[] }>
) => {
	let members = { ...state.members };
	members[action.payload.groupId] = [...action.payload.members];
	return {
		...state,
		members,
	};
};

export const selectGroup = (
	state: GroupReducer,
	action: PayloadAction<string>
) => {
	if (action.payload in state.map)
		return {
			...state,
			group: { ...state.map[action.payload] },
		};
	else if (Object.keys(state.map)?.length)
		return {
			...state,
			group: { ...state.map[Object.keys(state.map)[0]] },
		};
	return {
		...state,
	};
};

/**
 * TODO: UPDATE MEMBER.ROLE
 * ! MODIFICATION NOT TAKEN IN COUNT
 * @param state
 * @param action
 * @returns
 */
export const manageRole = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		member: Member;
		userId: string;
		role: UserType;
	}>
) => {
	const members = { ...state.members[action.payload.groupId] };
	let member = members.find(
		(member) => member._id === action.payload.userId
	) as Member;
	member.role = action.payload.role;

	return {
		...state,
	};
};

export const addRessourceToGroup = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		groupKey: RessourceType;
		ressourceId: string;
	}>
) => {
	const { groupId, groupKey, ressourceId } = action.payload;
	let groupsMap = { ...state.map };

	if (groupId && groupId in groupsMap) {
		switch (groupKey) {
			case "project":
				groupsMap[groupId].projectIds.push(ressourceId);
				break;
			case "dictionary":
				groupsMap[groupId].dictionaryIds.push(ressourceId);
				break;
			case "annotation-campaign":
				groupsMap[groupId].annotationCampaignIds.push(ressourceId);
				break;
			case "ontology":
				groupsMap[groupId].ontologyIds.push(ressourceId);
				break;
			case "pattern":
				groupsMap[groupId].patternIds.push(ressourceId);
				break;
		}
	}

	return {
		...state,
		map: groupsMap,
	};
};

export const deleteRessourceFromGroup = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		groupKey: RessourceType;
		ressource: RessourceRealType;
	}>
) => {
	const { groupId, groupKey, ressource } = action.payload;
	let groupsMap = { ...state.map };

	if (groupId && groupId in groupsMap) {
		switch (groupKey) {
			case "project":
				groupsMap[groupId].projectIds = groupsMap[
					groupId
				].projectIds.filter((_id: string) => _id !== ressource._id);
				break;
			case "dictionary":
				groupsMap[groupId].dictionaryIds = groupsMap[
					groupId
				].dictionaryIds.filter((_id: string) => _id !== ressource._id);
				break;
			case "annotation-campaign":
				groupsMap[groupId].annotationCampaignIds = groupsMap[
					groupId
				].annotationCampaignIds.filter(
					(_id: string) => _id !== ressource._id
				);
				break;
			case "ontology":
				groupsMap[groupId].ontologyIds = groupsMap[
					groupId
				].ontologyIds.filter((_id: string) => _id !== ressource._id);
				break;
			case "pattern":
				groupsMap[groupId].patternIds = groupsMap[
					groupId
				].patternIds.filter((_id: string) => _id !== ressource._id);
				break;
		}
	}

	return {
		...state,
		map: groupsMap,
	};
};

export const deleteGroupMember = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		memberId: string;
	}>
) => {
	const { groupId, memberId } = action.payload;
	let map = { ...state.map };
	let members = { ...state.members };

	members[groupId] = members[groupId].filter((member: Member) => {
		return member._id !== memberId;
	});

	map[groupId].userIds = map[groupId].userIds.filter((userId: string) => {
		return userId !== memberId;
	});

	return {
		...state,
		members,
		map,
	};
};
